import API from "./API.jsx"
import mensagemApp from './config/MensagemApp.js'

let jsonErro = { codigo: 400, mensagem: mensagemApp.msgErroApi }

export default class IndicacoesAPI {
    static async enviarIndicacao(dados, data, tipoPessoa) {
        return await API.post("/appProfissional/acessar", {
            dominio: dados.dominio,
            rota: '/indicarCliente',
            method: 'POST',
            metodo: 'POST',
            data: {
                ...data,
                idProf: dados.idProf,
                tipo: tipoPessoa
            },
            cabecalho: {
                token: dados.token
            }
        }).then(res => {
            return res.data
        }).catch(rej => {
            return jsonErro
        })
    }

    static async relatorioIndicados(dados, data) {
        return await API.post("/appProfissional/acessar", {
            dominio: dados.dominio,
            rota: '/relatorioIndicados',
            method: 'GET',
            metodo: 'GET',
            params: {
                idProf: dados.idProf,
                inicio: data.inicio,
                fim: data.fim
            },
            cabecalho: {
                token: dados.token
            }
        }).then(res => {
            return res.data
        }).catch(rej => {
            return jsonErro
        })
    }

    static async relatorioIndicadosServicos(dados, data) {
        return await API.post("/appProfissional/acessar", {
            dominio: dados.dominio,
            rota: '/relatorioServicosIndicados',
            method: 'GET',
            metodo: 'GET',
            params: {
                idProf: dados.idProf,
                inicio: data.inicio,
                fim: data.fim
            },
            cabecalho: {
                token: dados.token
            }
        }).then(res => {
            return res.data
        }).catch(rej => {
            return jsonErro
        })
    }
}