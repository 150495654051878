import styles from './gears.module.css'

export default function Gears() {
    return (
        <div className='d-flex justify-content-center'>
            <div className={styles.parent}>
                <div className={styles.gear1}>
                    <div className={styles.center} />
                    <div className={styles.tooth} />
                    <div className={styles.tooth} />
                    <div className={styles.tooth} />
                    <div className={styles.tooth} />
                </div>
                <div className={styles.gear2}>
                    <div className={styles.center} />
                    <div className={styles.tooth} />
                    <div className={styles.tooth} />
                    <div className={styles.tooth} />
                    <div className={styles.tooth} />
                </div>
                <div className={styles.gear3}>
                    <div className={styles.center} />
                    <div className={styles.tooth} />
                    <div className={styles.tooth} />
                    <div className={styles.tooth} />
                    <div className={styles.tooth} />
                </div>
            </div>
        </div>
    )
}