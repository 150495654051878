import { useEffect, useState } from "react"
import { MdCheck, MdClose } from "react-icons/md"

import styled from "styled-components"
import styles from './RadarVerificacao.module.css'

const Div = styled.div`
    position: relative;
    margin: auto;
    margin-top: 2rem;
    padding: 0rem 1rem;
    height: 50vw;
    width: 50vw;
    max-height: 12rem;
    max-width: 12rem;
    border-radius: 50%;
    box-shadow: 0px 0px 10px #00000077;
    background: ${props => props.estado ? "white" : props.sucesso ? "#00770033" : "#77000033"};
`

export default function RadarVerificacao({ estado, sucesso, fn }) {
    return (
        <Div estado={estado} sucesso={sucesso}>
            {
                estado
                    ? <div className={styles.radar}>
                        <div className={styles.pointer} />
                        <span className={styles['radarLine1']} />
                        <span className={styles['radarLine2']} />
                        <span className={styles['radarLine3']} />
                        <span className={styles['radarLine4']} />
                        <span className={styles['dot-1']} />
                        <span className={styles['dot-2']} />
                        <span className={styles['dot-3']} />
                        <span className={styles['dot-4']} />
                        <span className={styles['dot-5']} />
                    </div>
                    : <>
                        {sucesso
                            ? <div className={styles.radarOff} onClick={fn} style={{
                                position: "relative"
                            }}>
                                <MdCheck size={"100%"} color="green" />
                                <p
                                    style={{
                                        position: "absolute",
                                        bottom: "-1.5rem",
                                        lineHeight: 1,
                                        textAlign: "center",
                                        width: "100%",
                                        opacity: 0.5
                                    }}
                                >
                                    Clique aqui para recarregar
                                </p>
                            </div>
                            : <div className={styles.radarOff} onClick={fn} style={{
                                position: "relative"
                            }}>
                                <MdClose size={"100%"} className={styles.red} />
                                <p
                                    style={{
                                        position: "absolute",
                                        bottom: "-1.5rem",
                                        lineHeight: 1,
                                        textAlign: "center",
                                        width: "100%",
                                        opacity: 0.5
                                    }}
                                >
                                    Clique aqui para recarregar
                                </p>
                            </div>
                        }
                    </>
            }
        </Div >
    )
}