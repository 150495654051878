
import Resizer from "react-image-file-resizer"

const maxSize = 2048

export async function reduceImage(image) {
	if (image) {
		const canvas = document.createElement("canvas"),
			oriWidth = image.width,
			oriHeight = image.height

		let newWidth, newHeight

		if (oriWidth > oriHeight) {
			newWidth = maxSize
			newHeight = (oriHeight / oriWidth) * maxSize
		} else {
			newHeight = maxSize
			newWidth = (oriWidth / oriHeight) * maxSize
		}

		canvas.width = newWidth
		canvas.height = newHeight

		const ctx = canvas.getContext("2d")
		ctx.drawImage(image, 0, 0, newWidth, newHeight)
		return "data:image/jpg;base64," + canvas.toDataURL("image/jpeg", 0.6).split(",")[1]
	}
}

export const resizePic = async file => {
	let base_image = await returnSizeMedia(file)
	let tamanhos = getMediaSize(base_image.w, base_image.h, 512, 512)

	return new Promise((resolve) => {
		Resizer.imageFileResizer(
			file,
			tamanhos.width,
			tamanhos.height,
			"png",
			5,
			0,
			(uri) => {
				resolve(uri);
			},
			"file"
		)
	})
}

export const getMediaSize = (iw, ih, mw, mh) => {
	let widthPercent = mw / iw;
	let heightPercent = mh / ih;
	let smallestPercent = Math.min(widthPercent, heightPercent);

	return {
		width: parseInt(iw * smallestPercent),
		height: parseInt(ih * smallestPercent),
	}
}

export const returnSizeMedia = (file) => {
	return new Promise(res => {
		let reader = new FileReader()

		reader.readAsDataURL(file)
		reader.onload = function (e) {
			let image = new Image()

			image.src = e.target.result
			image.onload = function () {
				res({
					w: this.width,
					h: this.height,
				})
			}
		}
	})
}

export const verificarFakeGPS = () => {
	try {
		if (window.AndroidInterface)
			return window.AndroidInterface.isFakeGPS()
	} catch {
		return false
	}
}

export const verificarBootClick = () => {
	let retorno = false;
	try {
		if (!!window.AndroidInterface) {
			retorno = window.AndroidInterface.isAccessibilityActive();
		}
	} catch {

	}
	//console.log('verificarBootClick ==> ', retorno)
	return retorno

}


export function print(msg) {
	if (window.AndroidInterface)
		window.AndroidInterface.showAndroidVersion(msg)
	else
		alert(msg)
}
