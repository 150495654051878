import { Check, Close } from "@mui/icons-material"
import { Card } from "@mui/material"
import { Row, Col } from "react-bootstrap"
import { MdCheck, MdClose, MdLocationOn, MdTouchApp, MdUndo } from 'react-icons/md'
import styled from "styled-components"

const Div = styled.div`
    width: 100%;
    margin-top: 1rem;
    &:disabled {
        color: red !important;
    }
`
const ColunaIndex = styled.div`
    position: relative;
    width     : 15%;
    display: flex;
    align-items: center;
    justify-content: center;
`
const Centralizado = styled.div`
    height: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
`

const ColunaEndereco = styled.div`
    display       : flex;
    flex-direction: column;
    align-items   : center;
    width         : 70%;
    text-transform: uppercase;
    line-height   : 1;
    margin        : 0;
    padding       : 0;
    font-size     : 0.8rem;
    font-weight   : bold;
`

const MarcadorPonto = styled.div`
    position        : relative;
    display         : flex;
    font-weight     : 800;
    font-size       : 0.8rem;
    align-items     : center;
    justify-content : center;
    background-color: white;
    color: ${props => (props.disabled ? '#888' : 'var(--primaria)')};
    border          : 2px solid ${props => (props.disabled ? '#888' : 'var(--primaria)')};
    border-radius   : 50%;
    height          : 1.6rem;
    width           : 1.6rem;
`

export default function CardEndereco(props) {
    return <Div disabled={props.disabled}>
        <Card
            onClick={props.disabled ? () => { } : props.onClick}
            style={{
                maxHeight: '90vh',
                overflow: 'auto',
                color: props.cor ?? "auto"
            }}
            className={
                (props.className ?? "py-2 position-relative") + (
                    props.disabled
                        ? ' text-disabled'
                        : ''
                ) + (
                    props.retorno
                        ? props.disabled
                            ? ' bgPriBrighter text-light'
                            : ' bgPri text-light'
                        : ' bg-light'
                )
            }
        >
            <div className="d-flex w-100" style={{
                paddingBottom: props.recebimento ? '1rem' : '0',
            }}>
                <ColunaIndex>
                    <Centralizado>
                        <MarcadorPonto disabled={props.disabled}>
                            {props.id}
                            {props.confirmaEntrega && <div
                                className={(props.confirmaEntrega == "S"
                                    ? "bg-success"
                                    : "bg-danger") + " text-white small"}
                                style={{
                                    width: "1.5rem",
                                    textAlign: "center",
                                    borderRadius: "50%",
                                    height: "1.5rem",
                                    position: "absolute",
                                    top: "0",
                                    opacity: 0.3
                                }}
                            >
                                {props.confirmaEntrega == "S"
                                    ? <>
                                        <Check />
                                    </>
                                    : <>
                                        <Close />
                                    </>}
                            </div>}
                        </MarcadorPonto>

                    </Centralizado>
                </ColunaIndex>
                <ColunaEndereco>
                    {props.retorno && <div className="small">
                        <MdUndo size="22" /> Retorno
                    </div>}
                    <div>
                        {props.endereco}{props.complemento != '' && <span> - {props.complemento}</span>}
                    </div>
                </ColunaEndereco>
                <ColunaIndex>


                    {props.icone
                        ? <MdLocationOn size={24} />
                        : <MdTouchApp size={24} />
                    }
                </ColunaIndex>
            </div>

            {props.observacao && <div className={"w-100 text-center small opacity-75" + (props.recebimento ? ' pb-3' : '')}>
                <div className="fw700">
                    Observação
                </div>
                <div className="px-3" style={{ lineHeight: '1' }}>
                    {props.observacao}
                </div>
            </div>}

            {props.recebimento && props.valor != '' && <div
                style={{ fontSize: '0.8rem', marginBottom: props.trocarPonto ? "" : "-0.5rem" }}
                className="bg-warning w-100 text-center"
            >
                Receber neste ponto {isNaN(props.valor) ? '' : new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                }).format(props.valor)}
            </div>}

            {props.trocarPonto && <div className="bg-dark text-white w-100 py-2 small" style={{ marginBottom: "-0.5rem", marginTop: "0.5rem", paddingLeft: "3.8rem" }}>
                Trocar próximo ponto
            </div>}
        </Card>
    </Div>
} 