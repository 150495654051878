import { useContext, useState } from "react"
import { motion } from "framer-motion"
import TopoMenu from "../../../componentes/gerais/TopoMenu"
import MenuLateral from "../../../componentes/gerais/Menu"
import { Col, Row } from "react-bootstrap"
import { Campo } from "../../../componentes/form/campo"
import { Spinner } from "../../../componentes/Spinner"
import { configAnim } from "../../../servicos/config/configAnim"
import IndicacoesAPI from "../../../servicos/IndicacoesAPI"
import { ContextoGeral } from "../../../contextos/ContextoGeral"
import { Alert, Card, Divider } from "@mui/material"

import { format, parse } from 'date-fns'

export default function RelatoriosIndicacoes() {
    const [menu, setMenu] = useState(false)

    const [resposta, setResposta] = useState('')
    const [data, setData] = useState({})
    const [lista, setLista] = useState('')

    const [buscando, setBuscando] = useState(false)

    const { dados, setDados } = useContext(ContextoGeral)

    const handleData = (ev) => {
        if (ev.target.id == 'dataInicial') {
            setData({ ...data, inicio: ev.target.value })
        } else {
            setData({ ...data, fim: ev.target.value })
        }
    }

    async function todosIndicados() {
        setBuscando(true)
        setLista('')
        setResposta(
            <div className="mt-4">
                <Spinner />
            </div>
        )

        let resposta = await IndicacoesAPI.relatorioIndicados(dados, data)

        if (resposta.resultado) {
            setResposta(
                <Alert severity="success" className="text-center">
                    <div>
                        {resposta.mensagem}
                    </div>
                    {resposta.dados.length > 0 ? (
                        <div>
                            Quantidade de indicados: {resposta.dados.length}
                        </div>
                    ) : ('')}
                </Alert>
            )

            if (resposta.dados != '') {
                setLista(
                    <div className="pb-3 g-0">
                        {resposta.dados.map(indicacao => {
                            return (
                                <Card key={indicacao.id} className="p-3 mt-3">

                                    <h4>{`#${indicacao.id}`}</h4>
                                    <div style={{ opacity: 0.8 }}>
                                        <big>{indicacao.nome}</big>
                                        {indicacao.empresa ? (
                                            <>
                                                <Divider />
                                                <small>{indicacao.empresa}</small>
                                            </>
                                        ) : ('')}

                                        <Divider />
                                        <small>{indicacao.email}</small>
                                        <Divider />
                                        <small>{indicacao.celular}</small>
                                        <Divider />
                                        <small>{indicacao.telFixo}</small>
                                        <Divider />
                                        <small>{format(parse(indicacao.dataCadastro, 'yyyy-MM-dd HH:mm:ss', new Date()), "HH:mm dd/MM")}</small>
                                    </div>
                                </Card>
                            )
                        })}
                    </div>
                )
            }
        } else {
            setResposta(
                <Alert severity="warning" className="text-center">
                    {resposta.mensagem}
                </Alert>
            )
        }
        setBuscando(false)
    }

    async function servicosIndicados() {
        setBuscando(true)
        setLista('')
        setResposta(
            <div className="mt-4">
                <Spinner />
            </div>
        )

        let resposta = await IndicacoesAPI.relatorioIndicadosServicos(dados, data)

        if (resposta?.resultado) {
            setResposta(
                <Alert severity="success" className="text-center">
                    <div>
                        {resposta.mensagem}
                    </div>
                    <div>
                        Quantidade de indicados: {resposta.dados.length}
                    </div>
                </Alert>
            )

            if (resposta.dados && resposta.dados.length > 0) {
                setLista(
                    <div className="pb-3 g-0">
                        {resposta.dados.map(indicacao => {

                            return (
                                <Card key={indicacao['s.id']} className="p-3 mt-3">
                                    <h4>{`#${indicacao['s.id']}`}</h4>
                                    <div style={{ opacity: 0.8 }}>
                                        <big>
                                            {indicacao['t.nome']}
                                        </big>
                                        <Divider />
                                        <small>
                                            {new Intl.NumberFormat('pt-BR', {
                                                style: 'currency',
                                                currency: 'BRL'
                                            }).format(indicacao['s.valor'])}
                                        </small>
                                        <Divider />
                                        <small>
                                            {format(parse(indicacao['s.dataHora'], 'yyyy-MM-dd HH:mm:ss', new Date()), "HH:mm dd/MM")}
                                        </small>
                                        <Divider />
                                    </div>
                                </Card>
                            )
                        })}
                    </div>
                )
            }
        } else {
            setResposta(
                <Alert severity="warning" className="text-center">
                    {resposta.mensagem}
                </Alert>
            )
        }
        setBuscando(false)
    }

    return (
        <motion.div
            style={{ height: '100%' }}
            initial={configAnim.initial}
            animate={configAnim.animate}
            exit={configAnim.exit}
            transition={configAnim.transition}
        >
            <TopoMenu voltar="/app/indicarCliente" titulo="RELATÓRIO DE INDICAÇÕES" setShow={setMenu} />
            <div className="mx-3">
                <div>
                    <div className="text-center my-3">
                        selecione um intervalo de datas:
                    </div>
                    <Row>
                        <Col xs={6}>
                            <Campo id="dataInicial" tipo="date" label="Data Inicial" value={data.inicio} onChange={handleData} />
                        </Col>
                        <Col xs={6}>
                            <Campo id="dataFinal" tipo="date" label="Data Final" value={data.fim} onChange={handleData} />
                        </Col>
                    </Row>
                    <Row className="mt-3 mx-0">
                        <button onClick={todosIndicados} disabled={buscando}>Todos os Indicados</button>
                    </Row>
                    <Row className="mt-3 mx-0">
                        <button onClick={servicosIndicados} disabled={buscando}>Serviços dos Indicados</button>
                    </Row>
                    <Row className="mt-3 mx-0">
                        {resposta}
                        {lista}
                    </Row>
                </div>
                <div />
            </div>
            <MenuLateral show={menu} setShow={setMenu} ativo="2" />
        </motion.div>
    )
}