import { useContext, useEffect, useState, React } from "react"
import { Alert, IconButton, MenuItem } from "@mui/material"
import { MdDelete } from "react-icons/md"
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { Spinner } from "../../../../componentes/Spinner"
import TopoMenu from "../../../../componentes/gerais/TopoMenu";
import MenuLateral from "../../../../componentes/gerais/Menu";
import { ContextoGeral } from "../../../../contextos/ContextoGeral"
import { motion } from "framer-motion"
import { configAnim } from "../../../../servicos/config/configAnim"

import { Button } from "react-bootstrap"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import APISemNode from "../../../../servicos/APISemNode";

export default function EnderecosOrdenados() {
	const [loading, setLoading] = useState(true)
	const [gif, setGif] = useState(false)

	const [menu, setMenu] = useState(false)
	const [idCliente, setIdCliente] = useState(0)
	const [ponto1, setPonto1] = useState([])
	const [enderecos, setEnderecos] = useState([])
	const [validado, setValidado] = useState(true)
	const [mensagem, setMensagem] = useState('')
	const [estadoMensagem, setEstadoMensagem] = useState('error')
	const [mostrarMensagem, setMostrarMensagem] = useState(false)
	const [retornarDados, setRetornarDados] = useState([])
	const [idServico, setIdServico] = useState(0)
	const [esconderEnderecos, setEsconderEnderecos] = useState(true)
	const [delivery_id, setDelivery_id] = useState([])

	const { dados } = useContext(ContextoGeral)

	const { state } = useLocation();

	const [open, setOpen] = useState(false);

	const navegar = useNavigate();

	function navegacao() {
		navegar('/app/servicos', {
			state: {
			}
		})
	}

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleRemoverEndereco = () => {
		handleClickOpen();
	};

	function confirmarRemocao(endereco) {

		removerEndereco(endereco);

		handleClose();
	};

	useEffect(() => {
		setTimeout(() => setLoading(false), 500)
		if (state && state.idCliente && state.ponto1 && state.enderecos) {
			setIdCliente(state.idCliente)
			setPonto1(state.ponto1)
			setEnderecos(state.enderecos)
			setRetornarDados({
				idCliente: state.idCliente,
				enderecos: state.enderecos,
				qClientes: state.qClientes
			})
		} else console.log("DEBUG não entrou na condição do state")
	}, [])

	async function removerEndereco(id) {
		setLoading(true)

		const novosEnderecos = enderecos.dados.enderecos.data.filter((endereco) => endereco.id !== id);
		const novosDados = {
			dados: {
				coletaP1: enderecos.dados.coletaP1,
				enderecos: {
					data: novosEnderecos
				}
			}
		};

		setEnderecos(novosDados)

		const obj = {
			lista: [ponto1, ...novosDados.dados.enderecos.data],
			token: dados.token
		}

		await APISemNode.requisicaoPOST(`https://${dados.dominio}`, obj, "md/painelLumen/lumen/abbiamo/ordenarEnderecos", "").then(res => {

			if (res.mensagem == 'Já está ordenado pela menor distância ou não foi possível ordenar.') {

				const lista = {
					dados: {
						coletaP1: ponto1,
						enderecos: {
							data: novosDados.dados.enderecos.data
						}
					}
				}

				setEnderecos(lista);
			}

			if (res.sucesso) {
				setEnderecos(res);
				setValidado(true)
			}

		}).catch(rej => {
			setValidado(false);
		}).finally(() => {
			setLoading(false)
		})

	};


	async function gravarServico() {
		setMostrarMensagem(true)
		setGif(true)

		const gravarEnderecos = {
			dominio: dados.dominio,
			idCliente: idCliente,
			pontos: [
				ponto1,
				...enderecos.dados.enderecos.data
			]
		}

		await APISemNode.requisicaoPOST(`https://${dados.dominio}`, gravarEnderecos, "md/mdLumen/lumen/abbiamo/gravarServico", "").then(res => {

			if (res.sucesso && res.dados) {
				setMensagem('Serviço gravado com sucesso.')
				setEstadoMensagem('success')

				const arr = enderecos.dados.enderecos.data.map((endereco, i) => {
					return endereco.delivery_id
				})
				arr.unshift(ponto1.delivery_id)

				setDelivery_id(arr);
				setEsconderEnderecos(false);
				setIdServico(res.dados);

			} else {
				setMensagem('Erro ao salvar servico.')
				setMostrarMensagem(true)
			}
		}).catch(rej => {
			setMostrarMensagem(true)
			setMensagem("Não foi possível acessar o servidor, verifique sua rede e tente novamente.")
		})

		setGif(false)
	}

	useEffect(() => {
		setRetornarDados(prev => ({ ...prev, enderecos: enderecos }))
	}, [enderecos])

	useEffect(() => {

		if (idServico) {
			const dadosSetarProfissional = {
				idServico: idServico,
				idMotoboy: dados.idProf
			}

			APISemNode.requisicaoPOST(`https://${dados.dominio}`, dadosSetarProfissional, "md/painelLumen/lumen/setarProfServico", "").then(res => {
				console.log('sucesso');
			}).catch(rej => {
				console.log('falhou');
			})
		}

	}, [idServico])

	useEffect(() => {

		if (delivery_id && idServico) {

			const dadosEnviar = {
				idServico: idServico,
				deliveryId: delivery_id
			}

			APISemNode.requisicaoPUT(`https://${dados.dominio}`, dadosEnviar, "md/painelLumen/lumen/atualizarIdEndereco", "").then(res => {
				console.log('sucesso');
			}).catch(rej => {
				console.log('falhou');
			})
		}

	}, [delivery_id])

	return <motion.div
		style={{
			display: 'flex',
			height: '100%',
			flexDirection: 'column',
			paddingBottom: '1rem'
		}}
		initial={configAnim.initial}
		animate={configAnim.animate}
		exit={configAnim.exit}
		transition={configAnim.transition}
		layout
	>
		<TopoMenu
			voltar={retornarDados.qClientes == 1 && enderecos?.dados?.enderecos?.data?.length == 1
				? "/app/puxarServico"
				: "/app/abbiamo"
			}
			titulo="Rota para entrega"
			setShow={setMenu}
			state={retornarDados}
		/>
		<MenuLateral show={menu} setShow={setMenu} />
		<div className="mx-3">
			{esconderEnderecos
				? loading
					? <div className="mt-4 text-center">
						<Spinner />
					</div>
					: validado
						? enderecos?.dados?.enderecos?.data?.length > 0
							? <>
								<div style={{ maxHeight: "80vh", overflowY: "auto" }}>
									{enderecos.dados.enderecos.data.map((endereco, i) => {
										return <>
											<MenuItem className="p-2 m-0 w-100">
												<IconButton onClick={handleRemoverEndereco}>
													<MdDelete />
												</IconButton>
												<label htmlFor={endereco.id} className="text-truncate small">
													{endereco.rua}, {endereco.numero}, {endereco.bairro}, {endereco.cidade}, {endereco.estado}
												</label>
											</MenuItem>

											<Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
												<DialogTitle id="alert-dialog-title" style={{ color: "#100940" }}>Confirmar remoção</DialogTitle>
												<DialogContent>
													<DialogContentText id="alert-dialog-description">
														Tem certeza que deseja excluir este endereço?
													</DialogContentText>
												</DialogContent>
												<DialogActions>
													<Button className="btnFullW" onClick={handleClose} style={{ backgroundColor: "#100940", color: "#FFFFFF" }} >Cancelar</Button>
													<Button className="btnFullW" onClick={() => confirmarRemocao(endereco.id)} autoFocus style={{ backgroundColor: "#100940", color: "#FFFFFF" }} >Confirmar</Button>
												</DialogActions>
											</Dialog>
										</>
									})}
								</div>

								<button
									className="btnFullW mt-3"
									disabled={gif}
									onClick={gravarServico}
								>
									Gravar Serviço
								</button>
							</>
							: <Alert severity="error" className="mx-3 mt-4 text-center">
								Houve um problema ao ordenar os endereços.
							</Alert>
						: <Alert severity="error" className="mx-3 mt-4 text-center">
							Não foi possivel ordenar os endereços.
						</Alert>
				: ''}

			<div className="w-100 mt-3 align-items-center justify-content-center">
				{gif ?
					<Spinner />

					: mostrarMensagem && <Alert severity={estadoMensagem} className="mx-3 mt-4 text-center">
						{mensagem}
					</Alert>}

				{estadoMensagem == 'success'
					? <button
						className="btnFullW mt-3"
						disabled={gif}
						onClick={navegacao}
					>
						Ir para tela de serviços
					</button>
					: ''}
			</div>

		</div>
	</motion.div >

}