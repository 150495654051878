// COMPONENTE PARA PREENCHER ALTURA MÁXIMA

import styled from "styled-components"

const Div = styled.div`
    height: 100%;
    display: flex;
    align-items: ${bottom => bottom === true ? 'flex-start' : 'flex-end'};
    position: fixed;
    bottom: 0;
`

export default function AltMax(props) {
    return (
        <Div bottom={props.bottom}>
            {props.children}
        </Div>
    )
}