import Badge from 'react-bootstrap/Badge'
import styled from 'styled-components'
import { Spinner } from '../Spinner'

const Pill = styled(Badge)`
    flex-direction: row;
    position: relative;
    width: 9rem;
    background-color: #ffffff;
    padding: 0.5rem 0.7rem !important;
    color: var(--primaria-darkest);
    box-shadow: 0px 2px 7px 2px #00000033;
    margin-left: 0.5rem;
`

const Text = styled.div`
    display: flex;
    justify-content: start;
    text-align: left;
    font-size: 0.8rem;
`

const Span = styled.div`
    color: ${props => props.cor};
    position: absolute;
    right: 0.7rem;
    top: 50%;
    transform: translate(0, -50%);
    font-size: 0.85rem;
`

const DivSpinner = styled.div`
    color: ${props => props.cor};
    position: absolute;
    right: 25%;
    top: 50%;
`

export function AtivoPill(props, { carregando }) {
    return (
        <div className='mx-2'>
            <Pill pill bg='light' style={{ color: 'var(--primaria)' }}>
                <Text>
                    Você está
                </Text>
                {props.texto == '' ? (
                    <DivSpinner cor={props.cor}>
                        <Spinner size="0.125rem" />
                    </DivSpinner>
                ) : (<Span cor={props.cor}>
                    {props.texto}
                </Span>
                )}
            </Pill >
        </div>
    )
}