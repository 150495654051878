import { Alert, Button, Card, Zoom } from "@mui/material"
import { motion } from "framer-motion"
import { useState } from "react"
import { Col, Row } from "react-bootstrap"
import { MdCheck, MdMonetizationOn, MdTimer, MdTouchApp, MdUndo } from "react-icons/md"

import MenuLateral from "../../../componentes/gerais/Menu"
import TopoMenu from "../../../componentes/gerais/TopoMenu"
import Status from "../../../componentes/uteis/Status"
import { Spinner } from "../../../componentes/Spinner"
import { useNavigate } from 'react-router-dom';

import { Backdrop } from "@mui/material"
import { useEffect } from "react"

import ServicoAPI from "../../../servicos/ServicoAPI"
import { useContext } from "react"
import { ContextoGeral } from "../../../contextos/ContextoGeral"

import { configAnim } from "../../../servicos/config/configAnim"
import Refresher from "../../../componentes/uteis/Refresher"
import MensagemApp from "../../../servicos/config/MensagemApp.js"
import CheckPermission from "../../../servicos/config/CheckPermission.js"
import { compareAsc, format, parse } from "date-fns"
import CardEndereco from "../../../componentes/form/CardEndereco"
import APISemNode from "./../../../servicos/APISemNode.js"

import { verificarFakeGPS, verificarBootClick } from '../../../servicos/Uteis.js'
import AlertDialog, { BootClickAlert, FakeGpsAlert } from "./../../../componentes/gerais/Dialog.jsx"

import icon from "./../../../assets/icones/relogio.svg"
import FakeGPSBlock from "../../../componentes/uteis/FakeGPSBlock"
import API from "../../../servicos/API.jsx"

export default function PuxarServico() {
	const [servicos, setServicos] = useState({})

	const [exibirFakeGPS, setExibirFakeGPS] = useState(false)
	const [exibirBootClick, setExibirBootClick] = useState(false)

	/** Relacionada permissao FakeGps e BootClick */
	const [carregando, setCarregando] = useState(true)
	const [BDLoading, setBDLoading] = useState(false)

	const [modal, setModal] = useState(false)
	const [selected, setSelected] = useState(null)

	const [menu, setMenu] = useState(false)
	const [BD, setBD] = useState(false)

	const [msg, setMsg] = useState(<Spinner />)
	const [valido, setValido] = useState(false)
	const [texto, setTexto] = useState('')
	const [labelPuxaServico, setLabelPuxaServico] = useState('PUXAR SERVIÇOS')
	const [idServico, setIdServico] = useState(0)

	const [mostrarBotao, setMostrarBotao] = useState(false)

	const { dados } = useContext(ContextoGeral)

	async function verificarAntifraude() {
		if (window.AndroidInterface?.isFakeGPS && window.AndroidInterface?.recoverVersion && window.AndroidInterface.recoverVersion() > 100.80) {
			if (dados?.configFakeGPS?.opcao != 'NF' && await window.AndroidInterface.isFakeGPS()) {
				setExibirFakeGPS(true)
			}

			if (dados?.configBootClick && (dados?.configBootClick?.opcao != 'NF' && verificarBootClick())) {
				setExibirBootClick(true)
			}
		}
	}

	const navegar = useNavigate()

	function navegacao(url) {
		navegar(url, {
			state: {

			}
		})
	}

	useEffect(() => {
		verificarAntifraude()
	}, [])

	async function puxar(id) {
		setBDLoading(false)

		try {
			if (window.AndroidInterface?.isFakeGPS && window.AndroidInterface?.recoverVersion && window.AndroidInterface.recoverVersion() > 100.80) {
				if (dados?.configFakeGPS?.opcao != 'NF' && await window.AndroidInterface.isFakeGPS()) {
					setExibirFakeGPS(true)

					if (dados?.configFakeGPS?.opcao == "B")
						return
				}

				if (dados?.configBootClick && (dados?.configBootClick?.opcao != 'NF' && verificarBootClick())) {
					setExibirBootClick(true)

					if (dados?.configBootClick?.opcao == "B")
						return
				}
			}

			setTexto(`Você está puxando o serviço ${id}`)
			setIdServico(id)
			setBD(true)
		} catch (err) {
			console.error(err)
		}
	}

	const handleClose = () => {
		setBD(false)
		setIdServico(0)
	}

	async function buscarServicos() {
		let local = {
			latitude: 0,
			longitude: 0
		}

		setServicos({})

		let statusPermissao = true

		try {

			if (window.AndroidInterface?.recoverVersion() > 100.80) {
				// Verifica permissão de localização esta ativa
				if (statusPermissao && !CheckPermission.checkLocationAvailable()) {
					statusPermissao = false
					window.AndroidInterface.showPermissaoLocalizacao(MensagemApp.msgPermissaoGPS)
				}

				// Verifica localização esta habilitada
				if (statusPermissao && !CheckPermission.checkLocationEnabled()) {
					statusPermissao = false
					window.AndroidInterface.showHabilitarLocalizacao(MensagemApp.msgHabilitarGPS)
				}
			}
		} catch {
			console.error(MensagemApp.funcIndisponivel)
		}

		if (statusPermissao) {
			if (!!window.AndroidInterface) {
				local = JSON.parse(window.AndroidInterface?.getLocation())
			}

			if ((local == '') || (local.latitude == 0 || local.longitude == 0)) {
				setMsg(MensagemApp.msgErroLaLo)
				setCarregando(false)
				setValido(true)
			} else {
				let retorno = await ServicoAPI.buscarPuxarServicos(dados, local)

				if (retorno?.resultado) {
					setValido(true)
					if (retorno.dados.length > 0)
						setServicos(retorno.dados)
					else
						setMsg(retorno.mensagem)
				} else {
					if (retorno?.mensagem)
						setMsg(retorno.mensagem)
					else
						setMsg(MensagemApp.msgErroApi)

					setValido(true)
				}

				if (retorno?.aux && retorno.aux?.labelPuxaSerivo)
					setLabelPuxaServico(retorno.aux.labelPuxaSerivo)

				setCarregando(false)
			}
		} else {
			setMsg(MensagemApp.msgErroLaLo)
			setCarregando(false)
			setValido(true)
		}
	}

	async function responder() {
		setBDLoading(true)

		let bloqueio = false

		try {
			if (window.AndroidInterface?.isFakeGPS && window.AndroidInterface?.recoverVersion && window.AndroidInterface.recoverVersion() > 100.80) {
				if (dados?.configFakeGPS?.opcao != 'NF' && await window.AndroidInterface.isFakeGPS()) {
					setExibirFakeGPS(true)
					bloqueio = dados?.configFakeGPS?.opcao == "B"
				}

				if (dados?.configBootClick && (dados?.configBootClick?.opcao != 'NF' && verificarBootClick())) {
					setExibirBootClick(true)

					if (!bloqueio)
						bloqueio = dados?.configBootClick?.opcao == "B"
				}
			}
		} catch {
			console.error(MensagemApp.funcIndisponivel)
		}

		if (bloqueio) return

		let retorno = await ServicoAPI.responderPuxarServico(dados, idServico)

		if (retorno?.resultado)
			if (!!window.AndroidInterface)
				window.AndroidInterface.showAndroidVersion(retorno.mensagem)
			else alert(retorno.mensagem)
		else
			if (window.AndroidInterface)
				window.AndroidInterface.showAndroidVersion(MensagemApp.timeout)
			else alert(MensagemApp.timeout)

		setBD(false)
		setCarregando(true)
	}

	useEffect(() => {
		if (carregando) buscarServicos()
	}, [carregando])

	const valAbbiamo = ['mototaxionline.srv.br', 'logmapp.com.br', 'mappsistemas.com', 'boyvinyentregas.com.br', 'mljexpress.com'].includes(dados.dominio)

	useEffect(() => {
		API.get(`https://${dados.dominio}/md/painelLumen/lumen/abbiamo/abbiamoPuxarEnderecos`, {
			headers: {
				Authorization: "Bearer " + dados.token
			},
			params: {
				idProf: dados.idProf,
				token: dados.token
			}
		}).then(async res => {
			res = res.data

			if (res.sucesso && res.dados && res.dados.length > 0) {
				let ids = ""

				await res.dados.map(cliente => {
					ids += cliente.value + ", "
				})

				API.get(`https://${dados.dominio}/md/mdLumen/lumen/abbiamo/validarIntAbbiamo`, {
					headers: {
						Authorization: "Bearer " + dados.token
					},
					params: {
						idsClientes: ids.slice(0, -2)
					}
				}).then(res => {
					res = res.data
					if (res.sucesso) setMostrarBotao(true)
				}).catch(rej => {
					console.error("erro na requisição que verifica se integração está ativa: ", rej)
				})
			}
		}).catch(rej => {
			console.error("erro na requisição do botão abbiamo: ", rej)
		})
	}, [])

	return (
		<>
			<motion.div
				style={{ height: '100%' }}
				key={carregando}
				initial={configAnim.initial}
				animate={configAnim.animate}
				exit={configAnim.exit}
				transition={configAnim.transition}
			>
				{carregando ? (
					<div className="vh-100">
						<Spinner />
					</div>
				) : (
					<>
						<TopoMenu voltar="/app/home" titulo={labelPuxaServico} setShow={setMenu} />
						<FakeGpsAlert
							open={exibirFakeGPS}
							setOpen={setExibirFakeGPS}
							opcao={dados?.configFakeGPS?.opcao}
						/>

						<BootClickAlert
							open={exibirBootClick}
							setOpen={setExibirBootClick}
							opcao={dados?.configBootClick?.opcao}
						/>
						{valAbbiamo && mostrarBotao && <div className="m-3 px-3">
							<Button
								variant="contained"
								id='Abbiamo'
								disabled={false}
								fullWidth
								onClick={() => navegacao('/app/abbiamo')}
							>
								Endereços
							</Button>
						</div>}

						<div>
							{servicos.length > 0 ? (
								<>
									<div className="pb-3 px-3">
										{Object.values(servicos).map((servico, i) => {
											const urgente = servico['s.urgente'] == "S"
											const economico = servico['s.freteExpresso'] == "S"

											return <Zoom
												key={servico['s.id']}
												in={servicos.length > 0}
												style={{
													transitionDelay: (servicos.length > 0) ? i * 200 : 0
												}}
											>
												<Card className={"bgGradientGrey mb-3 p-2 " + (urgente ? "text-danger" : economico ? "text-info" : "")}>
													{urgente && <div className="mb-3">
														<div className="bg-danger d-flex align-items-center gap-2 text-light p-2" style={{ margin: '-0.5rem' }}>
															<img src={icon} style={{ height: '1rem', filter: "brightness(10)" }} />
															<div>
																Serviço urgente
															</div>
														</div>
													</div>}

													{economico && <div className="mb-3">
														<div className="bg-info d-flex align-items-center gap-2 text-light p-2" style={{ margin: '-0.5rem' }}>
															<MdMonetizationOn />
															<div>
																Serviço econômico
															</div>
														</div>
													</div>}

													<Row className="mb-2">
														<Col className="">
															<span style={{
																fontWeight: 800, color: urgente
																	? 'var(--bs-danger)'
																	: economico
																		? 'var(--bs-info)'
																		: 'var(--primaria)'
															}}>
																#{servico['s.id']}
															</span>
														</Col>

														<Col className="d-flex justify-content-end small" style={{ opacity: 0.75, fontWeight: 600 }}>

															{servico['labelDistancia']
																? <>{servico['labelDistancia']}: {servico['s.distancia'].replace('.', ',')}km</>
																: <>{servico['s.distancia'].replace('.', ',')}km distância total</>}
														</Col>
													</Row>

													<div className="row mt-1">
														{(servico['s.valor'] || servico['s.valorProfissional']) && <div className="col">
															<span style={{ fontSize: "2rem", color: "var(--bs-success)", fontWeight: 800 }}>
																{servico['s.valor'] == "-"
																	? "-"
																	: servico['s.valor'] > 0
																		? new Intl.NumberFormat('pt-BR', {
																			style: 'currency',
																			currency: 'BRL'
																		}).format(servico['s.valor'])
																		: new Intl.NumberFormat('pt-BR', {
																			style: 'currency',
																			currency: 'BRL'
																		}).format(servico['s.valorProfissional'])}
															</span>
														</div>}

														<div className="col text-end">
															<span style={{ fontSize: "2rem", fontWeight: 600 }}>
																{servico['distanciaProf'].replace('.', ',') + "km"}
															</span>
														</div>
													</div>
													<div className="row mb-1">
														<div className="col"></div>
														<div className="col text-end" style={{ fontSize: "0.75rem", marginTop: "-0.5rem" }}>
															Distância de você
														</div>
													</div>

													<div className="row">
														<div className="col">
															{servico['s.dataHoraColetar'] && <Status
																danger={urgente}
																color={economico && 'var(--bs-info)'}
																agendado={servico['s.dataHoraColetar'] != "0000-00-00 00:00:00"}
																texto={
																	<span style={{ fontSize: "1rem" }}>
																		{servico['s.dataHoraColetar'] === "0000-00-00 00:00:00"
																			? "AGORA"
																			: format(parse(servico["s.dataHoraColetar"], 'yyyy-MM-dd HH:mm:ss', new Date()), "dd/MM HH:mm")}
																	</span>
																} fill />}
														</div>
													</div>

													<div className="d-flex flex-column justify-content-between mb-2" onClick={() => {
														setModal(true)
														setSelected(servico)
													}}>
														<Row className="mt-2">
															<Col style={{ width: '90%' }}>
																<Row>
																	<Col className="d-inline-block text-truncate">
																		{servico.enderecos[0]['e.rua']}
																	</Col>
																</Row>


																<Row className="mt-2">
																	{servico.enderecos?.length == 2
																		? <Col className="d-inline-block text-truncate" style={{
																			color: urgente ? 'var(--bs-danger)' : economico ? 'var(--bs-info)' : 'var(--primaria-darkest)'
																		}}>
																			{servico.enderecos[1]['e.rua']}
																		</Col>
																		: <><Col xs="4" className="d-flex align-items-center opacity-50 text-truncate" style={{
																			color: urgente ? 'var(--bs-danger)' : economico ? 'var(--bs-info)' : 'var(--primaria-darkest)'
																		}}>
																			<div className="small d-flex align-items-center">
																				{(servico.enderecos?.length - 1) > 1
																					? <>
																						+{servico.enderecos.length - 1}
																						Endereços
																					</> : ''}
																			</div>
																		</Col>
																			<Col
																				xs="8"
																				className="d-flex align-items-center justify-content-end opacity-50"
																			>
																				{servico['s.retorno'] == 'S' && <div
																					style={{ color: urgente ? 'var(--bs-danger)' : 'var(--primaria-darkest)' }}
																					className="d-flex align-items-center gap-1 small">
																					<MdUndo size="22" />
																					Retorno ao 1º ponto
																				</div>}
																			</Col>
																		</>
																	}
																</Row>
															</Col>

															<Col style={{
																width: '10%',
																display: 'flex',
																justifyContent: 'center',
																alignItems: 'center',
																paddingLeft: 0
															}}>
																<MdTouchApp size={22} />
															</Col>
														</Row>
													</div>
													{servico['osAgendado']
														? compareAsc(new Date(), parse(servico['dataAceite'], 'yyyy-MM-dd HH:mm:ss', new Date())) >= 0
															? <Row>
																<Col className="text-center">
																	<Button
																		color="success"
																		startIcon={<MdCheck size={32} />}
																		onClick={() => { puxar(servico['s.id']) }}
																	>
																		Puxar serviço
																	</Button>
																</Col>
															</Row>
															: ''
														: <Row>
															<Col className="text-center">
																<Button
																	color="success"
																	startIcon={<MdCheck size={32} />}
																	onClick={() => { puxar(servico['s.id']) }}
																>
																	Puxar serviço
																</Button>
															</Col>
														</Row>}

													{servico['osAgendado'] && <div style={{
														margin: "-0.5rem",
														marginTop: "0.5rem",
														padding: '0.5rem',
														backgroundColor: urgente ? 'var(--bs-danger)' : 'var(--primaria)',
														color: 'white',
														display: "flex"
													}}>
														<div style={{ width: '50%' }}>
															<small className="d-flex align-items-center">
																<MdTimer size={24} />
																{format(parse(servico['dataAgendamento'], 'yyyy-MM-dd HH:mm:ss', new Date()), "HH:mm")}
															</small>
														</div>
														<div className="d-flex align-items-center justify-content-end" style={{ width: '50%' }}>
															{compareAsc(new Date(), parse(servico['dataAceite'], 'yyyy-MM-dd HH:mm:ss', new Date())) != 1 && <small>
																Disponível às {format(parse(servico['dataAceite'], 'yyyy-MM-dd HH:mm:ss', new Date()), "HH:mm")}
															</small>}
														</div>
													</div>}
												</Card>
											</Zoom>
										})}
									</div>
								</>
							) : (
								<div className="position-relative">
									<Alert severity="error" className="mx-3 mt-4 text-center">
										{msg}
									</Alert>
									{valido && <Refresher
										setCarregando={setCarregando}
									/>}
								</div>
							)}
						</div>
						{!carregando && !valido && <AlertDialog mensagem={{
							titulo: "Ative sua localização!",
							mensagem: "Sua localização está desativada e não será possível aceitar as cotações, ative sua localização para liberar o acesso.",
							opcao: "B"
						}} />}
						<MenuLateral show={menu} setShow={setMenu} ativo="0" />
					</>
				)}
			</motion.div>

			<Backdrop
				sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={modal}
				onClick={() => {
					setModal(false)
				}}
			>
				{selected && <Card className="p-3" style={{ width: '90vw', overflow: 'auto', maxHeight: '80vh' }}>
					<h3>#{selected && selected['s.id']}</h3>
					<h5 style={{ marginBottom: '-0.8rem' }}>Lista de endereços</h5>
					{Object.keys(selected).length > 0 && selected.enderecos.map(endereco => {
						return <CardEndereco
							id={endereco['es.ponto']}
							endereco={endereco['e.rua']}
							complemento={endereco['e.complemento']}
							contato={endereco['e.contato']}
							tel={endereco['e.telefone']}
							observacao={endereco['e.observacao']}
							retorno={endereco['e.retorno']}
							recebimento={endereco['e.endPontoReceber']}
						/>
					})}
				</Card>}
			</Backdrop>
			<Backdrop
				open={BD}
			>
				{BDLoading ? (
					<div>
						<Spinner />
					</div>
				) : (
					<Card className="p-3">
						<h2>Confirme sua solicitação</h2>
						<p className="text-center small">
							{texto}
						</p>
						<Row>
							<Col>
								<Button
									color="success"
									variant="contained"
									className="w-100"
									onClick={responder}
								>
									Confirmar
								</Button>
							</Col>
							<Col>
								<Button
									color="error"
									variant="text"
									className="w-100"
									onClick={() => { setBD(false) }}
								>
									Cancelar
								</Button>
							</Col>
						</Row>
					</Card>
				)}
			</Backdrop>

			<FakeGPSBlock />
		</>
	)
}