import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { motion } from "framer-motion"

import TopoMenu from "../../../componentes/gerais/TopoMenu"
import MenuLateral from "../../../componentes/gerais/Menu"

import { configAnim } from "../../../servicos/config/configAnim"
import { Avatar, Chip, Divider } from "@mui/material"
import { ContextoGeral } from "../../../contextos/ContextoGeral"
import { MdEdit, MdLogout, MdStar } from "react-icons/md"
import { LogoBottom } from "./styles"
import ProfissionalAPI from "../../../servicos/ProfissionalAPI"

export default function Perfil() {
	const navegar = useNavigate()

	const [menu, setMenu] = useState(false)
	const [avaliacaoProf, setAvaliacaoProf] = useState(false)

	const { dados, deslogar } = useContext(ContextoGeral)

	useEffect(() => {
		ProfissionalAPI.avaliacao(dados).then(res => {
			setAvaliacaoProf(res.resultado)
		})
	}, [])

	return (
		<motion.div
			initial={configAnim.initial}
			animate={configAnim.animate}
			exit={configAnim.exit}
			transition={configAnim.transition}
			className="h-100 d-flex flex-column justify-content-between"
		>
			<div>
				<TopoMenu voltar="/app/home" titulo="PERFIL" setShow={setMenu} />
				<div className="mx-3 py-4 d-flex align-items-center position-relative">
					<Avatar src={dados.foto} sx={{ width: 64, height: 64 }} />
					<h4 className="ms-3">{dados.nome}</h4>
					<div className="position-absolute w-100 h-100 d-flex justify-content-end align-items-end pb-2">
						<Chip color="primary" label={'#' + (dados.codigo ?? dados.idProf)} size="small" />
						{dados.tipoVeiculo ? (
							<Chip color="primary" label={dados.tipoVeiculo} size="small" className="ms-2" />
						) : ('')}
					</div>
				</div>
				<Divider />
				<div
					className="mx-3 py-3 d-flex align-items-center"
					onClick={() => { navegar('/app/perfil/meusDados') }}
				>
					<MdEdit style={{ color: 'var(--primaria-pura)' }} size={30} />
					<h5 className="ms-3 mb-0">Meus dados</h5>
				</div>
				<Divider />
				{avaliacaoProf && <>
					<div
						className="mx-3 py-3 d-flex align-items-center"
						onClick={() => { navegar('/app/perfil/avaliacao') }}
					>
						<MdStar style={{ color: 'var(--primaria-pura)' }} size={30} />
						<h5 className="ms-3 mb-0">Minha Avaliação</h5>
					</div>
					<Divider />
				</>}
				<div
					className="mx-3 py-3 d-flex align-items-center"
					onClick={() => { deslogar() }}
				>
					<MdLogout style={{ color: 'var(--primaria-pura)' }} size={30} />
					<h5 className="ms-3 mb-0">Sair</h5>
				</div>
				<Divider />
			</div>
			<div className="text-center w-100 mb-3">
				<LogoBottom
					src={dados.config?.logo}
					className="mb-3"
					alt=""
					onClick={() => { navegar("/app/home") }}
				/>
			</div>
			<MenuLateral show={menu} setShow={setMenu} />
		</motion.div>
	)
}