import { useContext } from "react";
import { MdSend } from "react-icons/md";
import styled from "styled-components";
import { ContextoGeral } from "../../contextos/ContextoGeral";

const Div = styled.div`
    position: absolute;
    /* position: sticky; */
    display: flex;
    bottom: 0;
    width: calc(100% - 1rem);
    height: calc(2rem + 5vh);
    background: transparent;
    background-color: var(--grey-800);
    transition: 0.4s;
    border-radius: 1rem;
    margin: 0.5rem;
    padding: 0.5rem;
`

const Text = styled.textarea`
    width: 80%;
    height: 100%;
    background-color: transparent;
    border: 0;
    resize: none;
    color: var(--primaria);
`

const Coluna = styled.div`
    background-color: transparent;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;    
`

export default function Escritor({ fn, conteudo, alterarConteudo }) {

    const { dados, setDados } = useContext(ContextoGeral)

    return (
        <Div>
            <Text value={conteudo} onChange={alterarConteudo}></Text>
            <Coluna>
                <div onClick={fn}>
                    <MdSend size={26} color={dados?.config?.corPrincipal ?? "#000"} />
                </div>
            </Coluna>
        </Div>
    )
}