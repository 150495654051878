import chroma from "chroma-js"
import { useContext, useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { Spinner } from "../../../componentes/Spinner"
import { ContextoGeral } from "../../../contextos/ContextoGeral"
import useTestChecker from "../../../hooks/general/useTestChecker"
import AppAPI from "../../../servicos/AppAPI"
import MensagemApp from "../../../servicos/config/MensagemApp"
import validarRedirecionamento from "../../../servicos/config/redirecionamento"
import { Main } from "./styles"
import { print } from "../../../servicos/Uteis"

export default function Inicial() {
	const { dados, setDados } = useContext(ContextoGeral)

	const [searchParams] = useSearchParams()
	const isTest = useTestChecker(dados.dominio)

	const [dominio, setDominio] = useState('')
	const [rota, setRota] = useState('/home')
	const [param, setParam] = useState('')

	const [configurado, setConfigurado] = useState(false)
	const [preContext, setPreContext] = useState({})

	const [redirect, setRedirect] = useState(false)

	const navegar = useNavigate()

	useEffect(() => {
		async function inicializar() {
			if (searchParams.get('navegar') !== null)
				setParam(searchParams.get('navegar'))

			const dom = searchParams.get('dominio')
			if (dom) {
				setDominio(dom)

				let config = await AppAPI.buscarConfig(dom)
				if (config?.resultado) {
					let json = {}
					let cor = config.dados.configApp.corPrincipal

					document.documentElement.style.setProperty('--primaria-pura', cor)

					if (chroma(cor).luminance() > 0.9)
						cor = chroma(cor).darken(0.75)
					else if (chroma(cor).luminance() > 0.82 && chroma(cor).luminance() <= 0.9)
						cor = chroma(cor).darken(0.5)
					else if (chroma(cor).luminance() > 0.75 && chroma(cor).luminance() <= 0.82)
						cor = chroma(cor).darken(0.25)

					document.documentElement.style.setProperty('--primaria', cor)
					document.documentElement.style.setProperty('--primaria-bright', chroma(cor).brighten(0.5))
					document.documentElement.style.setProperty('--primaria-brighter', chroma(cor).brighten(1))
					document.documentElement.style.setProperty('--primaria-brightest', chroma(cor).brighten(1.5))
					document.documentElement.style.setProperty('--primaria-dark', chroma(cor).darken(0.5))
					document.documentElement.style.setProperty('--primaria-darker', chroma(cor).darken(1))
					document.documentElement.style.setProperty('--primaria-darkest', chroma(cor).darken(1.5))
					document.documentElement.style.setProperty('--bs-primary', cor)

					config.dados.configApp.corPrincipal = chroma(cor).hex()
					json.config = config.dados.configApp
					json.config.logo = config.dados.configApk.links.logo
					json.dominio = searchParams.get('dominio')

					if (config.dados?.configFakeGPS)
						json.configFakeGPS = config.dados.configFakeGPS

					if (config.dados?.configBootClick)
						json.configBootClick = config.dados.configBootClick

					if (config.dados?.otimizacaoBateria)
						json.otimizacaoBateria = config.dados.otimizacaoBateria

					json.modulos = config.dados.modulos

					json.tutoriais = {
						mapa: true,
						cotacoes: true
					}

					if (config.dados?.urlBase) {
						try {
							if (window.AndroidInterface)
								window.AndroidInterface.saveUrlBase(config.dados.urlBase)
						} catch (error) {
							if (isTest) alert("Erro ao setar url base")
							console.error('Failed to GET: ' + error)
						}
					}

					if (window.AndroidInterface) {
						if (config.dados?.urlSocketIO) {
							try {
								let urlSocketIO = window.AndroidInterface.getUrlSocketIO()
								if (urlSocketIO != config.dados.urlSocketIO) {
									console.log('Atualizar urlSocketIO')
									window.AndroidInterface.saveUrlSocketIO(config.dados.urlSocketIO)
								}
							} catch (error) {
								if (isTest) alert("Erro ao setar url base")
								console.error('Failed to GET: ' + error)
							}
						}
					}

					setDados(json)
					setConfigurado(true)
				} else setRedirect(true)
			} else print("Sistema indisponível!")
		}

		inicializar()
	}, [])

	useEffect(() => {
		async function validarToken() {
			if (window.AndroidInterface) {
				let token = ""

				try {
					token = window.AndroidInterface.recoverToken()
				} catch {
					console.log("não foi possível carregar token")
				}

				if (token != '') {
					let retorno = await AppAPI.validarTokenApp(dominio, token)

					if (retorno?.resultado) {
						if (retorno.dados.dadosProf.ativo == 'N' || retorno.dados.dadosProf.ativo == '')
							setRota('/app/cadastro/pos')
						else if (param == '') {
							setRota('/app/home')

							if (retorno.dados.dadosProf.termoUso) {
								if (window.AndroidInterface) {
									let versionApp = 0

									try {
										versionApp = window.AndroidInterface.recoverVersion()
									} catch {
										console.log("Não foi possível recuperar a versão do aplicativo")
									}

									if (versionApp > 100.39)
										window.AndroidInterface.showTermoApp(retorno.dados.dadosProf.idProf)
								}
							}
						} else
							setRota(validarRedirecionamento(param))

						let json = { ...retorno.dados.token, ...retorno.dados.dadosProf }
						json = { ...json, ...dados }

						setPreContext(json)
					} else {
						try {
							window.AndroidInterface.showAndroidVersion(MensagemApp.msgDesconectado)
						} catch {
							console.log("Não foi possível enviar mensagem")
						}
						setRedirect(true)
					}
				} else setRedirect(true)
			} else setRedirect(true)
		}

		if (configurado) validarToken()
	}, [dados])

	useEffect(() => {
		(async () => {
			if (Object.keys(preContext).length != 0) {
				delete preContext.config

				setConfigurado(false)
				setDados({ ...preContext, ...dados })
				setRedirect(true)
			}
		})()
	}, [preContext])

	useEffect(() => {
		if (rota != '' && redirect) navegar(rota, {
			state: { viaNotificacao: param != "" }
		})
	}, [redirect])

	return <Main>
		<Spinner />
	</Main>
}