import "./cadastro.module.css";

import AppAPI from "../../../servicos/AppAPI";
import ProfissionalAPI from "../../../servicos/ProfissionalAPI";

import { motion } from "framer-motion";
import { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Spinner } from "../../../componentes/Spinner";
import { Campo } from "../../../componentes/form/campo";

import packinfo from "../../../../package.json";

import { Backdrop, Button, Card } from "@mui/material";
import { Alert, Form } from "react-bootstrap";
import { MdClose, MdDescription } from "react-icons/md";
import TopoMenu from "../../../componentes/gerais/TopoMenu";
import { ContextoGeral } from "../../../contextos/ContextoGeral";
import { configAnim } from "../../../servicos/config/configAnim";

export function Cadastro() {
	const navigate = useNavigate()

	const [result, setResult] = useState(" ")

	const { dados, setDados } = useContext(ContextoGeral)

	const [link, setLink] = useState("")

	const [nome, setNome] = useState("")
	const [email, setEmail] = useState("")
	const [celular, setCelular] = useState("")
	const [senha, setSenha] = useState("")

	const [CPF, setCPF] = useState("")

	const [emailSalvo, setEmailSalvo] = useState("")

	const [hasPassword, setHasPassword] = useState(false)
	const [termo, setTermo] = useState(false)
	const [show, setShow] = useState([false, null])
	const [mostrar, setMostrar] = useState(false)

	const [campos, setCampos] = useState([])

	if (!dados.dominio) {
		if (typeof window.AndroidInterface !== "undefined") {
			if (window.AndroidInterface.recoverToken() !== "") {
				setDados(
					Object.assign(dados, {
						token: window.AndroidInterface.recoverToken(),
					})
				);
			}
		}
	}

	async function config() {
		let retorno = await AppAPI.buscarConfigCadastro(dados);

		if (retorno.resultado) {
			setCampos(retorno.dados.campos);
		} else {
			console.error("Falha ao buscar configurações da tela de cadastro");
		}
	}

	async function cadastrar() {
		let idFirebase = "e5zOfIadRT6C_vrNeaw3LJ:APA91bGbJmj7l-g4ZxUv5EORIDx2PReDXMxmYcidbVXJ62f2rF8psFLlBcfkKWbWp25MwjymrisGxOFjclffh6vxBdW8vjatgaWavpaWLBpM9f3i230QbsRagDldDxYJHD1b4HXLJzZ5";
		let versao = "100.00";

		if (!!window.AndroidInterface) {
			idFirebase = window.AndroidInterface.recoverIdFirebase();
			versao = window.AndroidInterface.recoverVersion();
		}

		setResult(<div className="mt-3">
			<Spinner />
		</div>)

		let termoValidado = termo

		// CASO O LINK VENHA NULL, DEVERÁ PERMITIR PASSAR
		if (link === null)
			termoValidado = true

		if (termoValidado) {
			if (!dados.dominio)
				setResult(<div>
					<Navigate to="/" />
				</div>)
			else {
				const dadosEnvio = {
					nome: nome,
					email: email,
					celular: celular,
					senha: senha,
					cpf: CPF,
					aceiteTermos: termo
				}

				let data = await ProfissionalAPI.cadastrarProf(dadosEnvio, idFirebase, versao, dados)

				if (data?.resultado) {
					let jsonDados = {
						...dados,
						idProf: data.dados.id,
						token: data.dados.token,
						nome: data.dados.nome,
						email: email,
						status: data.dados.ativo,
					}
					setDados(jsonDados)

					let retornoToken = await AppAPI.gerarTokenJWT(jsonDados)

					if (retornoToken?.token !== "") {
						try {
							if (!!window.AndroidInterface) {
								window.AndroidInterface.saveToken(retornoToken.token)
								window.AndroidInterface.saveEmail(email)
								window.AndroidInterface.savePassword(senha)
							}
						} catch {
							console.error("não foi possível salvar dados do cliente no aplicativo.")
						}

						setResult(
							<Alert className="mt-3" key={"success"} variant={"success"}>
								{data.mensagem}
								{data.dados.ativo === "n" ? <Navigate to="/app/cadastro/pos" /> : <Navigate to="/app/home" />}
							</Alert>
						);
					} else {
						setResult(
							<Alert className="mt-3" key={"danger"} variant={"danger"}>
								{retornoToken.mensagem}
							</Alert>
						);
					}
				} else {
					setResult(
						<Alert className="mt-3" key={"danger"} variant={"danger"}>
							{data.mensagem ?? "Não foi possível cadastrar, o sistema não retornou uma resposta"}
							{data.dados?.erros && data.dados.erros.map((e) => <li key={e.campo}>{e.mensagem}</li>)}
						</Alert>
					);
				}
			}
		} else {
			setResult(
				<Alert className="mt-3" variant="danger">
					Você precisa aceitar os termos de uso para continuar!
				</Alert>
			)
		}
	}

	async function termoDeUso() {
		let retorno = await ProfissionalAPI.buscarTermosUso(dados);

		if (retorno?.resultado) {
			if (retorno.dados != "" && retorno.dados.link != "") {
				setLink(retorno.dados.link);
			}
		}
	}

	function camposDinamicosVal(termo) {
		switch (termo) {
			case "nome":
				return nome;
			case "cpf":
				return CPF;
			case "email":
				return email;
			case "celular":
				return celular;
			case "senha":
				return senha;
			default:
				break;
		}
	}

	function camposDinamicosSet(termo, e) {
		switch (termo) {
			case "nome":
				setNome(e.target.value);
				break;
			case "cpf":
				setCPF(e.target.value.replace(/\D/g, ""));
				break;
			case "email":
				setEmail(e.target.value);
				break;
			case "celular":
				setCelular(e.target.value);
				break;
			case "senha":
				setSenha(e.target.value);
				break;
			default:
				break;
		}
	}

	useEffect(() => {
		try {
			if (!!window.AndroidInterface) {
				if (window.AndroidInterface.recoverPassword())
					setHasPassword(true)

				setEmailSalvo(window.AndroidInterface.recoverEmail())
			}
		} catch {
			console.error("Não foi possível recuperar e-mail e senha neste aplicativo")
		}

		if (campos.length == 0) {
			config();
		}

		if (link == "") {
			termoDeUso();
		}
	}, []);

	const handleTermo = (e) => {
		setTermo(e.target.checked);
	};

	return <>
		<motion.div
			initial={configAnim.initial}
			animate={configAnim.animate}
			exit={configAnim.exit}
			transition={configAnim.transition}
		>
			<div
				className="vh-100 d-flex flex-column justify-content-between"
				style={{
					maxHeight: "100vh",
					overflowY: "auto",
				}}
			>
				<div>
					<TopoMenu voltar="/home" titulo="CADASTRO" />
					<div className="mx-3 d-flex flex-column justify-content-between">
						<h3 className="textCenter">Que bom ter você aqui!</h3>
						{hasPassword
							? <Card className="p-3 my-4">
								<h1>Atenção</h1>
								<p className="small">Você já possui uma conta cadastrada neste celular com o email: <b>{emailSalvo}</b>, deseja logar com esta conta?</p>
								<div className="d-flex">
									<Button onClick={() => navigate("/login")} variant="contained" fullWidth>Sim</Button>
									<Button onClick={() => setHasPassword(false)} color="error" fullWidth>Não</Button>
								</div>

							</Card>
							: <>
								<p className="textCenter">Digite seus dados pessoais</p>
								{campos.length > 0 &&
									campos.map((campo) => {
										return (
											<div key={campo.coluna}>
												<Campo
													className="mt-3"
													id={campo.coluna}
													label={campo.label}
													tipo={campo.coluna == "senha" ? "password" : campo.tipoCampo}
													mask={campo?.mask}
													value={camposDinamicosVal(campo.coluna)}
													onChange={(e) => camposDinamicosSet(campo.coluna, e)}
													obrigatorio={campo.obrigatorio}
													pattern={campo?.pattern}
												/>
											</div>
										);
									})}

								{link && (
									<>
										<div className="mt-3">
											<div
												className="h5 d-flex align-items-center"
											>
												<span onClick={() => setMostrar(true)}>
													<MdDescription size="28" />
													Ver termos de uso
												</span>
											</div>
										</div>
										<div className="d-flex gap-1">
											<Form.Check id="termos" type="checkbox" value={termo} onChange={handleTermo} />
											<Form.Label htmlFor="termos">Aceito os termos de uso</Form.Label>
										</div>
									</>
								)}
								<div id="retorno">{result}</div>
							</>}
					</div>
				</div>
				<div className="w-100 d-flex flex-column mt-3 mb-3 px-3">
					<div className="text-center mb-2 opacity-75 small">
						<div>
							Versão {!!window.AndroidInterface && window.AndroidInterface.recoverVersion()} • {packinfo.version}
						</div>
					</div>
					{!hasPassword && <Button variant="contained" onClick={cadastrar}>Próxima Etapa</Button>}
					<div className="d-flex justify-content-between mt-3">
						<Button size="small" onClick={() => setShow([true, "privacidade"])}><small>Política de privacidade</small></Button>
					</div>
				</div>
			</div>

			<Backdrop
				open={mostrar}
				onClick={() => {
					setMostrar(false);
				}}
				sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
			>
				<div
					style={{
						backgroundColor: "white",
						height: "100vh",
						width: "100%",
					}}
					className="d-flex flex-column"
				>
					<div
						className="p-3"
						onClick={() => {
							setMostrar(false);
						}}
					>
						<MdClose size="30" />
					</div>
					{!!link && <iframe style={{ height: "100vh" }} src={`${link}?exibirBtn=false`} title="política de privacidade" />}
				</div>
			</Backdrop>
		</motion.div>
		<Backdrop open={show[0]} sx={{ zIndex: 2000 }}>
			<div style={{ height: "100vh", width: "100%", backgroundColor: "white", zIndex: 2000 }}>
				<div className="d-flex justify-content-end p-3">
					<MdClose size={32} onClick={() => setShow([false, null])} />
				</div>
				<iframe
					style={{ height: "calc(100% - 5rem)", width: "100%" }}
					src={"https://" + dados.dominio + "/politicaPrivacidadeAndroid"}
					title="política de privacidade"
				/>
			</div>
		</Backdrop>
	</>
}
