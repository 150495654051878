import { Alert, Zoom } from "@mui/material"
import { format, parse } from "date-fns"
import { pt } from "date-fns/locale"
import { useState } from "react"
import styled from "styled-components"
import { Spinner } from "../../../componentes/Spinner"

const Div = styled.div`
    height: 100%;
    display: flex;    
    flex-direction: column-reverse;
    overflow: auto;
    padding-bottom: 1rem;
    padding-top: 1rem;
`

const TimeStamp = styled.div`
    text-align: center;
    font-size: 0.8rem;
    opacity: 0.8;
    color: var(--primaria);
`

const Data = styled.div`
    display: flex;
    flex-direction: column-reverse;
`

const Linha = styled.div`
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    justify-content: ${props => (props.user == true ? 'flex-end' : 'flex-end')};    
    align-content: ${props => (props.user == true ? 'flex-end' : 'flex-start')};    
    margin: 0.5rem;
`

const Tempo = styled.div`
    margin-top: 0.25rem;
    opacity: 0.8;
    font-size: 0.6rem;
    text-align:  ${props => (props.user == true ? 'left' : 'right')};
    content: ${props => (props.tempo ?? '')};
`

const BuscarMsg = styled.button`
    text-align: center;
    margin: auto;
    border-radius: 2rem;
    font-size: 0.75rem;
`

const CaixaMsgPendente = styled.div`
    position: absolute;
    right: 1rem;
    top: 0.25rem;
`

const Balao = styled.div`
    position: relative;
    left: ${props => (props.user == true ? '-1rem' : '1rem')};
    right: ${props => (props.user == true ? '1rem' : '-1rem')};
    background-color: ${props => (props.user == true ? (props.pendente ? 'var(--primaria-bright)' : 'var(--primaria)') : 'var(--primaria-bright)')};
    border-radius: ${props => (props.user == true ? '0.75rem 0.75rem 0 0.75rem' : '0.75rem 0.75rem 0.75rem 0')};
    padding: 0.75rem;
    word-wrap: break-word;
    font-size: 0.8rem;
    line-height: 1.1;
    color: white;
    width: 80%;
    transition: 0.4s;

    &:not(:last-of-type) {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    }

    & + div {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom: 1px solid ${props => (props.user ? 'var(--primaria-dark)' : '#00000044')};
        
        &:not(:last-of-type){
            border-top-right-radius: 0;
            border-top-left-radius: 0;
        }
    }

    &:first-of-type:before {
        content: "";
		position: absolute;
        background-color: ${props => (props.user == true ? (props.pendente == true ? 'var(--primaria-brighter)' : 'var(--primaria)') : 'var(--primaria-bright)')};
        border-left: 1rem solid ${props => (props.user == true ? 'transparent' : 'white')};
        border-right: 1rem solid ${props => (props.user == true ? 'white' : 'transparent')};
        border-top: 5px solid white;
        border-bottom: 10px solid transparent;
        left: ${props => (props.user == true ? '1rem' : '-1rem')};
        right: ${props => (props.user == true ? '-1rem' : '1rem')};
        bottom: 0;
        z-index: -1;
    }
`
function Mensagem({ children }) {
	const [carregando, setCarregando] = useState(false)
	return <div>{children}</div>
}

export default function ListaMensagens({ mensagens, novasMensagens, novasMensagensRef, atualizar, spinner, alerta, msgAlerta }) {

	return (
		<Div>
			{novasMensagens.sucesso === false
				? <Alert severity="info" className="mx-2 mb-0">
					{novasMensagens.msg}
				</Alert>
				: <>
					{novasMensagens?.conteudo ? novasMensagens.conteudo.map(dia => {
						return (
							<Data key={dia.key + dia.data}>
								{dia.blocos.map((bloco, ix) => {
									let attr = {}

									if (bloco.autor == 'prof') {
										attr = { user: true }
									}

									return (
										<Zoom
											key={bloco.key}
											in={true}
											style={{
												transitionDelay: true ? ix * 200 : 0
											}}
										>
											<Linha key={bloco.key} {...attr}>
												{bloco.mensagens.map((mensagem, iy) => {
													if (mensagem?.pendente) {
														attr = { ...attr, pendente: true }
													}

													return (
														<Zoom
															key={mensagem.id}
															in={true}
															style={{
																transitionDelay: true ? iy * 100 : 0
															}}
														>
															<Balao {...attr}>
																{mensagem.mensagem}
																<Tempo>
																	{mensagem.pendente ? (
																		<div className="position-relative">
																			<CaixaMsgPendente>
																				<Spinner right size="0.1rem" />
																			</CaixaMsgPendente>
																		</div>
																	) : ('')}
																	{mensagem.data.slice(11, 16)}
																</Tempo>
															</Balao>
														</Zoom>
													)
												})}
											</Linha>
										</Zoom>
									)
								})}
								<TimeStamp>
									<Alert severity="info" className="mx-2">
										Novas mensagens
									</Alert>
								</TimeStamp>
							</Data>
						)
					}) : ''}
				</>
			}
			{mensagens ? mensagens.map(dia => {
				return (
					<Data key={dia.key + dia.data}>
						{dia.blocos.map((bloco, ix) => {
							let attr = {}

							if (bloco.autor == 'prof') {
								attr = { user: true }
							}

							return (
								<Zoom
									key={bloco.key}
									in={true}
									style={{
										transitionDelay: true ? ix * 200 : 0
									}}
								>
									<span>
										<Linha key={bloco.key} {...attr}>
											{bloco.mensagens.map((mensagem, iy) => {
												return (
													<Zoom
														key={mensagem.id}
														in={true}
														style={{
															transitionDelay: true ? iy * 100 : 0
														}}
													>
														<Balao
															key={mensagem.key}
															pendente={mensagem?.pendente}
															ref={ref => {
																novasMensagensRef.current[mensagem.key] = ref
															}}
															{...attr}
														>
															{mensagem.mensagem}
															<Tempo>
																{mensagem.pendente ? (
																	<div className="position-relative">
																		<CaixaMsgPendente>
																			<Spinner right size="0.1rem" />
																		</CaixaMsgPendente>
																	</div>
																) : ('')}
																{mensagem.data.slice(11, 16)}
															</Tempo>
														</Balao>
													</Zoom>
												)
											})}
										</Linha>
									</span>
								</Zoom>
							)
						})}
						<TimeStamp>
							{format(parse(dia.data, 'yyyy-MM-dd', new Date()), "dd 'de' MMM 'de' Y", { locale: pt })}
						</TimeStamp>
					</Data>
				)
			}) : ('')}

			{spinner ? (
				<div className="my-3">
					<Spinner />
				</div>
			) : (
				<>
					{alerta ? (
						<Alert severity="warning" className="mx-4 text-center">
							{msgAlerta}
						</Alert>
					) : (
						<div className="text-center mb-3">
							<BuscarMsg onClick={atualizar}>
								Carregar mensagens anteriores
							</BuscarMsg>
						</div>
					)}
				</>
			)}
		</Div >
	)
}