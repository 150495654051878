import { useContext, useEffect, useState, useRef } from 'react'
import ReactDOMServer from 'react-dom/server'
import { useNavigate } from 'react-router-dom'

import { Place } from '@mui/icons-material'
import { Spinner } from './Spinner'

import styled from "styled-components"
import { ContextoGeral } from '../contextos/ContextoGeral'

import L, { divIcon } from "leaflet"
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import MarkerClusterGroup from "@changey/react-leaflet-markercluster"
import MapaProfMarker from './MapaProfMarker'


export const Div = styled.div`
    height: 100vh;
    width: 100%;
    background-color: #baa29c;
`

const IconLocationStyle = styled(Place)`
    transform: translate(-38%, -100%) scale(2);
    stroke: black;
    stroke-width: 0.5px;
    fill: ${props => (props?.cor ? props?.cor : "var(--primaria-brighter)")} !important;
`

const Teste = styled.div`
    background-color: #ccc;
    opacity: 0.5;
    position: absolute;
    height: 100vh;
    width: 100vw;
    z-index: 800;
    display: flex;
    align-items: center;
    justify-content: center;
`

const customCluster = (cluster) => divIcon({
    html: ReactDOMServer.renderToString(<span>{cluster.getChildCount()}</span>),
    className: "marker-cluster-customa",
    iconSize: L.point(50, 50, true)
})

export function DivMapa(props) {
    const navegar = useNavigate()

    const [mostrar, setMostrar] = useState(false)

    const [inicial, setInicial] = useState(true)
    const [teste, setTeste] = useState(false)

    const { dados } = useContext(ContextoGeral)

    const mapRef = useRef()

    const { current = {} } = mapRef

    const handleMarcador = (endereco) => {
        if (window.AndroidInterface && typeof window.AndroidInterface.abrirMaps === "function")
            window.AndroidInterface.abrirMaps(`${endereco.la}, ${endereco.lo}`)
        else alert('não está no android')
    }

    const iconLocation = (cor) => divIcon({
        html: ReactDOMServer.renderToString(<IconLocationStyle cor={cor} />),
        className: "iconesMapa"
    })

    useEffect(() => {
        if (!!props.enderecos && props.enderecos.length > 0)
            setMostrar(true)
    }, [props.enderecos])

    useEffect(() => {
        async function handleSetView() {
            if ((props.center[0] != -14.240 && props.center[1] != -51.925) &&
                (props.center[0] != 0 && props.center[1] != 0)) {
                setTeste(false)

                const actual = current.getCenter()

                if (current !== null) {
                    if (inicial && current.setView) {
                        current.setView(props.center, 16)
                        setInicial(false)
                    } else if (current.flyTo) {
                        let zoom = 12
                        if (actual.lat.toFixed(3) == props.center[0].toFixed(3) && actual.lng.toFixed(3) == props.center[1].toFixed(3)) {
                            if (current.getZoom() == 12)
                                zoom = 16
                            else zoom = 12
                        } else zoom = 16
                        current.flyTo(props.center, zoom, { duration: 0.5 })
                    }
                } else if (dados.statusOnline) setTeste(true)
            }
        }
        handleSetView()
    }, [props.center])

    return <>
        <MapContainer
            className="markercluster-map"
            center={(props.center[0] != 0 && props.center[1] != 0)
                ? props.center
                : [-14.2400732, -53.180501]
            }
            ref={mapRef}
            zoom={props.zoom}
            scrollWheelZoom={false}
            zoomControl={false}
            style={{ height: '100vh', width: '100vw', overflow: 'hidden' }}
        >
            {teste && <Teste>
                <Spinner />
            </Teste>}
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            <MapaProfMarker inicial={props.center} />

            {mostrar && <MarkerClusterGroup
                iconCreateFunction={customCluster}
            >
                {props.enderecos.map(endereco => {
                    if (endereco.la && endereco.lo)
                        return <Marker
                            key={endereco.id}
                            position={[endereco.la, endereco.lo]}
                            icon={iconLocation(endereco.cor)}
                        >
                            <Popup>
                                <div style={{
                                    fontSize: '1rem',
                                    fontWeight: '500'
                                }}>
                                    {endereco.idServico}
                                </div>
                                <div style={{ width: '50vw' }}>
                                    {endereco.end}
                                </div>
                                <div className="d-flex w-100 justify-content-between" style={{ gap: '0.5rem' }}>
                                    <button
                                        onClick={() => handleMarcador(endereco)}
                                        className='w-50 small'
                                    >
                                        GPS
                                    </button>
                                    <button
                                        onClick={() => navegar('/app/servicos/' + endereco.idServico)}
                                        className='w-50 small'
                                    >
                                        Ver Ponto
                                    </button>
                                </div>
                            </Popup>
                        </Marker >
                })}
            </MarkerClusterGroup>}
        </MapContainer >
        {props.children}
    </>
}