import { useState } from "react"
import { motion } from "framer-motion"
import TopoMenu from "../../../componentes/gerais/TopoMenu"
import MenuLateral from "../../../componentes/gerais/Menu"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { configAnim } from "../../../servicos/config/configAnim"

const Div = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;    
    justify-content: space-between;
`

export default function IndicarCliente() {
    const [menu, setMenu] = useState(false)

    return (
        <motion.div
            style={{ height: '100%' }}
            initial={configAnim.initial}
            animate={configAnim.animate}
            exit={configAnim.exit}
            transition={configAnim.transition}
        >
            <Div>
                <TopoMenu voltar="/app/home" titulo="INDIQUE UM CLIENTE" setShow={setMenu} />
                <div className="mx-3">
                    <h3 className="text-center mb-5">
                        Escolha uma das opções:
                    </h3>
                    <Link to="/app/indicarCliente/novaIndicacao">
                        <button className="btnFullW">Nova Indicação</button>
                    </Link>
                    <Link to="/app/indicarCliente/relatorio">
                        <button className="btnFullW mt-3">Ver Relatórios</button>
                    </Link>
                </div>
                <div></div>
            </Div>
            <MenuLateral show={menu} setShow={setMenu} ativo="2" />
        </motion.div>
    )
}