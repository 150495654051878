import { motion } from "framer-motion"
import { useContext, useEffect, useState } from "react"
import MenuLateral from "../../../componentes/gerais/Menu"
import TopoMenu from "../../../componentes/gerais/TopoMenu"
import { ContextoGeral } from "../../../contextos/ContextoGeral"
import { configAnim } from "../../../servicos/config/configAnim"
import ProfissionalAPI from "../../../servicos/ProfissionalAPI"

import StarRatings from "react-star-ratings"

export default function MinhaAvaliacao() {
    const [menu, setMenu] = useState(false)
    const [rate, setRate] = useState(0)

    const { dados } = useContext(ContextoGeral)

    async function buscarAvaliacao() {
        let avaliacao = await ProfissionalAPI.buscarAvaliacao(dados)

        setRate(parseFloat(avaliacao.dados.mediaEstrelas.toFixed(2)))
    }

    useEffect(() => {
        buscarAvaliacao()
    }, [])

    return (
        <motion.div
            className="telaAjustada"
            initial={configAnim.initial}
            animate={configAnim.animate}
            exit={configAnim.exit}
            transition={configAnim.transition}
        >
            <TopoMenu voltar="/app/perfil" titulo="MINHA AVALIAÇÃO" setShow={setMenu} />
            <div className="d-flex justify-content-center align-items-center flex-column" style={{ height: 'calc(100vh - 3rem)' }}>
                <h5>Sua avaliação é</h5>
                <p>{rate ?? 0} de 5 estrelas</p>
                <StarRatings
                    rating={rate}
                    starRatedColor="var(--primaria)"
                    starDimension="3rem"
                    starSpacing="0.25rem"
                />
            </div>
            <MenuLateral show={menu} setShow={setMenu} />
        </motion.div>
    )
}