import styled from "styled-components"

export const ColunaMarcador = styled.div`
	width: 10%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
`

export const ColunaEnderecos = styled.div`
	width: 90%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`

export const CirculoEndereco = styled.div`
	height: 1.5rem;
	width: 1.5rem;
	display: flex;
	justify-content: center;
	align-items: safe center;
	font-size: 1rem;
	font-weight: 700;
	border-radius: 50%;
	margin: 0.15rem 0;
`

const BallonBody = styled.div`
	text-align: center;
	max-width: 40vw;
	padding: 0.25rem;
	font-size: 0.8rem;
	margin: 0;
	line-height: 1rem;
	background-color: var(--primaria-dark);
	color: white;
	filter: drop-shadow(0 0 20px)
`

export const Balloon = ({ children, icon }) => {

	return <div>
		<BallonBody>
			{children}
			{!!icon && <div className="my-1">
				{icon}
			</div>}
		</BallonBody>
	</div>
}