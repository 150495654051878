import { useContext, useEffect, useState } from "react"
import { motion } from "framer-motion"
import TopoMenu from "../../../componentes/gerais/TopoMenu"
import MenuLateral from "../../../componentes/gerais/Menu"
import { Campo } from '../../../componentes/form/campo'

import { Alert, Zoom } from "@mui/material"

import styled from "styled-components"
import { configAnim } from "../../../servicos/config/configAnim"
import { Spinner } from "../../../componentes/Spinner"
import AppAPI from "../../../servicos/AppAPI"
import { ContextoGeral } from "../../../contextos/ContextoGeral"
import IndicacoesAPI from "../../../servicos/IndicacoesAPI"
import MensagemApp from "../../../servicos/config/MensagemApp"
import Refresher from "../../../componentes/uteis/Refresher"
import { useNavigate } from "react-router-dom"

const Div = styled.div`
    margin: 0rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

export default function NovaIndicacao() {
	const navegar = useNavigate()

	const [carregando, setCarregando] = useState(true)

	const [tipoPessoa, setTipoPessoa] = useState('')
	const [menu, setMenu] = useState(false)
	const [mostrar, setMostrar] = useState(false)

	const [data, setData] = useState({})

	const [titulo, setTitulo] = useState('')
	const [resposta, setResposta] = useState('')

	const [config, setConfig] = useState({})
	const [erroAPI, setErroAPI] = useState(false)

	const { dados } = useContext(ContextoGeral)

	async function enviar() {
		let json = {
			...config,
			carregandoBtn: true
		}
		setResposta("")

		setConfig(json)

		let retorno = await IndicacoesAPI.enviarIndicacao(dados, data, tipoPessoa)

		if (retorno?.resultado) {
			setResposta(<Alert severity="success" className="mt-3">
				{retorno?.mensagem}
			</Alert>)
			navegar('/app/indicarCliente')
		} else {
			setResposta(<Alert severity="error" className="mt-3">
				{retorno?.mensagem ? retorno?.mensagem : MensagemApp.msgErroApi}
				{retorno?.dados && <>
					{retorno.dados.erros.map(err => {
						return <li>{err.mensagem}</li>
					})}
				</>}
			</Alert>)
		}

		json = {
			...config,
			carregandoBtn: false
		}

		setConfig(json)
	}

	const handleTipoPessoa = (ev) => {
		setTipoPessoa(ev.target.value)
	}

	const handleData = (ev) => {
		let json = {
			...data,
			[ev.target.id]: ev.target.value
		}

		if (ev.target.value == '') {
			delete json[ev.target.id]
		}
		setData(json)
	}

	async function buscarCampos() {
		setErroAPI(false)
		let retorno = await AppAPI.buscarNovaIndicacao(dados)
		if (retorno?.resultado) {
			if (retorno.dados !== undefined) {
				setTitulo(retorno.dados?.tituloPagina.toUpperCase())
				setConfig(retorno.dados)

				setMostrar(true)
				setCarregando(false)
			}
		} else {
			setMostrar(true)
			setCarregando(false)
			setErroAPI(true)
		}
	}

	useEffect(() => {
		if (carregando) {
			buscarCampos()
		}
	}, [carregando])

	return (
		<motion.div
			initial={configAnim.initial}
			animate={configAnim.animate}
			exit={configAnim.exit}
			transition={configAnim.transition}
			style={{ height: '100%' }}
		>
			<TopoMenu voltar="/app/indicarCliente" titulo={titulo} setShow={setMenu} />
			{carregando ? (
				<Spinner />
			) : (
				<Div>
					{mostrar ? (
						<>
							{erroAPI ? (<>
								<div className="mx-2">
									<Refresher
										setCarregando={setCarregando}
									/>
									<Alert severity="error" className="text-center mt-4">
										{MensagemApp.msgErroApi}
									</Alert>
								</div>
							</>) : (<>

								<div>
									<div className="m-3 text-center">
										{config.msgObrigatorio}
									</div>
									{Object.entries(config.campos).map((e, i) => {
										let mask = ''
										if (e[0] == 'telefone') {
											mask = "(99) 9999-9999"
										} else if (e[0] == 'celular') {
											mask = "(99) 99999-9999"
										}

										return (
											<>
												{e[0] == 'camposVariaveis' && tipoPessoa != '' ? (
													<>
														{Object.values(e[1][tipoPessoa]).map((campo, i) => {

															return (
																<div key={campo.chave}>
																	<Zoom
																		in={true}
																		style={{ transitionDelay: i * 200 }}
																	>
																		<div>
																			<Campo
																				id={campo?.chave}
																				label={campo?.label}
																				tipo={campo?.tipoCampo == 'radio' ? 'radio' : ''}
																				className="mt-3"
																				obrigatorio={campo?.obrigatorio}
																				onChange={handleData}
																				value={data[e[1]?.chave]}
																			/>
																		</div>
																	</Zoom>
																</div>
															)
														})}
													</>
												) : (
													<>
														{e[0] != 'camposVariaveis'
															? <div key={e[1]?.chave}>
																<Zoom
																	in={true}
																	style={{ transitionDelay: i * 200 }}
																>
																	<div>
																		<Campo
																			id={e[1]?.chave}
																			label={e[1]?.label}
																			tipo={
																				e[1]?.tipoCampo == 'radio'
																					? 'radio'
																					: ''
																			}
																			className="mt-3"
																			obrigatorio={e[1]?.obrigatorio}
																			options={e[1]?.opcoes}
																			mask={mask}
																			onChange={e[1]?.tipoCampo == 'radio' ? handleTipoPessoa : handleData}
																			value={e[1]?.chave == 'tipoPessoa' ? tipoPessoa || '' : data[e[1]?.chave]}
																		/>
																	</div>
																</Zoom>
															</div>
															: ''}
													</>
												)}
											</>
										)
									})}
								</div>

								{resposta}
								{config.habilitado ? (
									<button className="my-3 w-100" onClick={enviar} disabled={config.carregandoBtn}>
										{config.carregandoBtn ? (
											<div className="my-2">
												<Spinner />
											</div>
										) : (
											<>
												{config.labelBtnIndicar}
											</>
										)}
									</button>
								) : ""}

							</>)}

						</>

					) : ('')}
				</Div>
			)
			}
			<MenuLateral show={menu} setShow={setMenu} ativo="2" />
		</motion.div >
	)
}