import { useState } from "react"
import { MdRefresh } from "react-icons/md"
import { BtnRedondo } from "../btnRedondo"
import { Spinner } from "../Spinner"
import { BlocoFlutuante } from "./blocoFlutuante"
import { IconButton } from "@mui/material"

export default function Refresher(props) {
    const [refresh, setRefresh] = useState(false)

    return <div className="w-100 d-flex justify-content-center">
        <IconButton
            className="bg-white"
            style={{ height: "3rem", width: "3rem", boxShadow: "0 0 5px #00000077" }}
            onClick={props?.onClick
                ? props.onClick
                : () => {
                    setRefresh(true)
                    setTimeout(() => {
                        props?.setCarregando(true)
                        setRefresh(false)
                    }, 1000)
                }}
        >
            {refresh ? (
                <div>
                    <Spinner size="0.15rem" />
                </div>
            ) : (
                <MdRefresh />
            )}
        </IconButton>
    </div>
}