import ProfissionalAPI from "../../../servicos/ProfissionalAPI"

import { motion } from "framer-motion"
import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Spinner } from "../../../componentes/Spinner"
import { Campo } from "../../../componentes/form/campo"

import { parse } from "date-fns"

import { ContextoGeral } from "../../../contextos/ContextoGeral"

import { Alert, Backdrop, Button, Card, CircularProgress, Collapse, IconButton, MenuItem, Zoom } from "@mui/material"
import { MdLogout, MdOpenInNew } from "react-icons/md"
import Gears from "../../../componentes/Gears"
import CheckPermission from "../../../servicos/config/CheckPermission"
import MensagemApp from "../../../servicos/config/MensagemApp"
import { configAnim } from "../../../servicos/config/configAnim"

import "./cadastroPos.module.css"
import { CameraAlt, Close, Collections } from "@mui/icons-material"
import useCamera from "../../../hooks/general/useCamera"
import useGallery from "../../../hooks/general/useGallery"

export function CadastroPos() {
	const navegar = useNavigate()

	const [carregando, setCarregando] = useState(true)
	const [erroAPI, setErroAPI] = useState(false)
	const [cadastroCompleto, setCadastroCompleto] = useState(false)
	const [dadosAdicionaisCompleto, setDadosAdicionaisCompleto] = useState(false)
	const [validRedirect, setValidRedirect] = useState(false)

	const [resultado, setResultado] = useState({})
	const [resposta, setResposta] = useState("")
	const [linkDeslogar, setLinkDeslogar] = useState("")

	const [mostrarDados, setMostrarDados] = useState(true)
	const [mostrarArquivos, setMostrarArquivos] = useState(true)

	const [msgCampos, setMsgCampos] = useState(false)
	const [msgArquivos, setMsgArquivos] = useState(false)

	const mostrarTabAtiva = mostrarDados ? "dadosGerais" : "arquivos"
	const [tabAtiva, setTabAtiva] = useState(mostrarTabAtiva)

	const [enviando, setEnviando] = useState(false)

	const { dados, setDados, deslogar } = useContext(ContextoGeral)

	const formataDataAmericano = (text) => {
		let myArray = text.split("/")
		let dataFormt = `${myArray[2]}-${myArray[1]}-${myArray[0]}`
		return dataFormt
	}

	const formataDataBrasileiro = (text) => {
		let myArray = text.split("-")
		let dataFormt = `${myArray[2]}/${myArray[1]}/${myArray[0]}`
		return dataFormt
	}

	async function handleCampo(event) {

		let json = { ...resultado };

		let icone = '';
		if (event.target.id === 'tipoVeiculo') {
			const item = Object.values(json.campos).filter(item => item.chave == event.target.id)[0]
			icone = item.opcoes.filter(opt => opt.value == event.target.value)[0].icone
		}

		if (event.target.id != "") {
			delete json.campos[event.target.id].erro;
			json.campos[event.target.id].valorEnviado = event.target.value;
			json.campos.tipoVeiculo.iconeEnviado = icone
		} else {
			json.campos[event.target.name].valorEnviado = event.target.value
			json.campos[event.target.name].iconeEnviado = icone
		}

		setResultado(json);
	}

	const handleSair = (event) => {
		setLinkDeslogar(`/?dominio=${dados.dominio}`)
		setDados({})

		if (!!window.AndroidInterface) {
			if (typeof window.AndroidInterface.saveToken === "function") {
				window.AndroidInterface.saveToken("");
			}
		}
	}

	async function handleCampoArquivo(event) {
		event.preventDefault();

		let json = { ...resultado };

		let statusPermissao = true;

		if (statusPermissao && !CheckPermission.checkCamera()) {
			statusPermissao = false;
			window.AndroidInterface.showPermissaoCamera(MensagemApp.msgPermissaoCamera);
		}

		if (statusPermissao && !CheckPermission.checkStorage()) {
			statusPermissao = false;
			window.AndroidInterface.showPermissaoArmazenamento(MensagemApp.msgPermissaoArmazenamento);
		}

		if (statusPermissao) {
			if (typeof window.AndroidInterface !== "undefined") {
				window.finalizouEnvio = function (res) {
					let retorno = JSON.parse(res);

					event.target.blur();

					if (retorno?.resultado) {
						if (!!window.AndroidInterface) {
							window.AndroidInterface.showAndroidVersion(retorno?.mensagem);
						}

						json.arquivos[event.target.id].valorEnviado = "Enviado";
						json.arquivos[event.target.id].enviado = true;
						setResultado(json);

						let completo = true;
						let completoAdicionais = true;

						Object.keys(json.arquivos).map((chave) => {
							if (json.arquivos[chave].valorEnviado == "" && json.arquivos[chave].obrigatorio) {
								completo = false;
							} else if (json.arquivos[chave].valorEnviado == "" && !json.arquivos[chave].obrigatorio) {
								completoAdicionais = false
							}
						})

						Object.keys(resultado.campos).map((chave) => {
							if (resultado.campos[chave].valorEnviado == "" && resultado.campos[chave].obrigatorio) {
								completo = false;
							} else if (resultado.campos[chave].valorEnviado == "" && !resultado.campos[chave].obrigatorio) {
								completoAdicionais = false
							}
						})

						setDadosAdicionaisCompleto(completoAdicionais);
					} else {
						if (window.AndroidInterface)
							window.AndroidInterface.showAndroidVersion(retorno?.mensagem ?? MensagemApp.timeout);
					}
				}
			}

			let tipoDoc = event.target.id;
			let idProfissional = parseInt(dados.idProf);
			let token = dados.token;

			try {
				window.AndroidInterface.ativaCamera("DC", idProfissional, 0, 0, "FP", tipoDoc, "arquivosAdicionais", token);
			} catch (err) {
				alert(err)
			}
		}
	}

	async function enviarCadastroPos() {
		setEnviando(true);
		let formulario = document.querySelectorAll("#dadosGerais input, #dadosGerais select");

		let dadosFormulario = { idProf: dados.idProf };

		for (let i = 0; i < formulario.length; i++) {
			if (formulario[i].type === "radio") {
				if (!dadosFormulario.hasOwnProperty(`${formulario[i].name}`)) {
					let nomeRadio = formulario[i].name;
					let statusValor = document.querySelector(`input[name='${nomeRadio}']:checked`)?.value ?? "";

					dadosFormulario = {
						...dadosFormulario,
						[formulario[i].name]: statusValor,
					};
				}
			} else {
				if (formulario[i].getAttribute("data-tipo") == "dateSelect") {
					// VALIDANDO SE DATA DIGITADA É VÁLIDA
					if (parse(formulario[i].value, "dd/MM/yyyy", new Date()) != "Invalid Date") {
						if (formulario[i].value.replace(/[^\d.-]/g, "").length == 8) {
							let data = formulario[i].value;

							if (formulario[i].id == "datVecAntt" || formulario[i].id == "datVecApolice") {
								data = formataDataAmericano(data);
							}

							dadosFormulario = {
								...dadosFormulario,
								[formulario[i].id]: data,
							};
						}
					}
				} else {
					if (formulario[i].value) {
						dadosFormulario = {
							...dadosFormulario,
							[formulario[i].id]: formulario[i].value,
						};
					}
				}
			}
		}

		dadosFormulario.icone = resultado?.campos?.tipoVeiculo?.iconeEnviado;
		let respostaAPI = await ProfissionalAPI.enviarCadastroPos(dados, dadosFormulario)

		if (respostaAPI?.resultado) {
			if (respostaAPI?.resultado) {
				if (!!window.AndroidInterface) {
					window.AndroidInterface.showAndroidVersion(respostaAPI.mensagem);
				}

				setResposta(
					<Alert severity="success" className="text-center mb-3">
						{respostaAPI.mensagem}
					</Alert>
				);

				let completo = true;
				let arqCompleto = true;
				let completoAdicionais = true;

				Object.keys(resultado.campos).map((chave) => {
					if (resultado.campos[chave].valorEnviado == "" && resultado.campos[chave].obrigatorio) {
						completo = false;
					} else if (resultado.campos[chave].valorEnviado == "" && !resultado.campos[chave].obrigatorio) {
						completoAdicionais = false;
					}

					if (['dataVencimentoHabilitacao', 'numeroHabilitacao', 'possuiBau', 'quantosLitros', 'placa', 'anoFabricacao', 'renavam', 'chassi', 'nAntt', 'datVecAntt', 'cilindradas', 'condumotoAberto'].includes(chave))
						if (resultado.campos.tipoVeiculo.valorEnviado == 'B')
							completo = true;

					if (['possuiBau', 'quantosLitros', 'PossuiMochila', 'condumotoAberto', 'cilindradas'].includes(chave))
						if (resultado.campos.tipoVeiculo.valorEnviado == 'U')
							completo = true;
				});


				Object.keys(resultado.arquivos).map((chave) => {
					if (resultado.arquivos[chave].valorEnviado == "" && resultado.arquivos[chave].obrigatorio) {
						arqCompleto = false;
					} else if (resultado.arquivos[chave].valorEnviado == "" && !resultado.arquivos[chave].obrigatorio) {
						completoAdicionais = false;
					}

					if (['fotoHabilitacao', 'fotoAntt', 'fotoBau', 'fotoDocVeiculo', 'condumoto'].includes(chave)) {
						if (resultado.campos.tipoVeiculo.valorEnviado == 'B') {
							arqCompleto = true;
						}
					}

					if (['fotoBau', 'fotoBag', 'condumoto'].includes('B', chave)) {
						if (resultado.campos.tipoVeiculo.valorEnviado == 'U') {
							arqCompleto = true;
						}
					}
				})

				if (completo && arqCompleto) {
					setTabAtiva("cadastroCompleto")
					setCadastroCompleto(true)
				}

				setDadosAdicionaisCompleto(completoAdicionais);
			} else {
				setResposta(
					<Alert severity="error" className="text-center mb-3">
						{respostaAPI?.mensagem ?? MensagemApp.erroAPI}
					</Alert>
				);
			}
		} else if (respostaAPI?.codigo == 400) {
			if (respostaAPI?.dados?.erros && respostaAPI.dados.erros.length > 0) {
				let json = resultado;

				Object.values(resultado.campos).forEach((campo) => {
					respostaAPI.dados.erros.forEach((erro) => {
						if (campo.chave == erro.campo) {
							json.campos[campo.chave].erro = erro.mensagem;
						}
					});
				});

				setResultado(json)
			}
			setResposta(
				<Alert severity="error" className="text-center mb-3">
					{respostaAPI?.mensagem ?? MensagemApp.erroAPI}
				</Alert>
			)
		} else setResposta(<Alert severity="error" className="text-center mb-3">
			{MensagemApp.timeout}
		</Alert>)
		setEnviando(false)
	}

	function filtrarCampos(campos) {

		const tipoVeiculo = campos?.tipoVeiculo ? [campos.tipoVeiculo] : [];
		const possuiBau = campos?.possuiBau ? [campos.possuiBau] : [];
		const quantosLitros = (campos?.possuiBau?.valorEnviado === 'S' && campos?.quantosLitros) ? [campos.quantosLitros] : [];

		const outrosCampos = Object.values(resultado.campos)
			.filter(e => e.chave !== "tipoVeiculo") // Adiciona os outros campos
			.filter(e => e.chave !== "possuiBau")
			.filter(e => e.chave !== "quantosLitros")
			.map((e, i) => { return e })

		return [...tipoVeiculo, ...possuiBau, ...quantosLitros, ...outrosCampos];
	}

	useEffect(() => {
		if (dados.dominio === undefined)
			navegar(linkDeslogar)
	}, [dados])

	useEffect(() => {
		async function teste() {
			if (resultado?.redirecionamento && resultado.redirecionamento?.exibir == "S" && resultado.redirecionamento?.label && resultado.redirecionamento?.url) {
				try {
					new URL(resultado.redirecionamento?.url)
					setValidRedirect(true)
				} catch (err) {
					setValidRedirect(false)
				}
			}
		}
		teste()
	}, [resultado])

	useEffect(() => {
		async function buscarCampos() {
			let respostaAPI = await ProfissionalAPI.posCadastro(dados)

			if (respostaAPI?.resultado && respostaAPI?.dados != "") {
				let todosCampos = true

				let qCampos = 0
				for (let prop in respostaAPI.dados.campos) {
					const valorEnviado = respostaAPI.dados.campos[prop].valorEnviado
					const obr = respostaAPI.dados.campos[prop].obrigatorio

					if (valorEnviado == "") {
						if (obr) todosCampos = false
						qCampos++
					}
				}

				let todosArquivos = true
				let qArquivos = 0
				for (let prop in respostaAPI.dados.arquivos) {
					const valorEnviado = respostaAPI.dados.arquivos[prop]?.valorEnviado
					const obr = respostaAPI.dados.arquivos[prop]?.obrigatorio

					if (valorEnviado == "") {
						if (obr) todosArquivos = false
						qArquivos++
					}
				}

				if (qCampos == 0) setMsgCampos(true)
				if (qArquivos == 0) setMsgArquivos(true)

				setMostrarDados(true)
				setMostrarArquivos(true)

				if (todosCampos && todosArquivos) {
					setTabAtiva("cadastroCompleto")
					setCadastroCompleto(true)
				}

				setResultado(respostaAPI.dados);
				setCarregando(false);
			} else {
				if (respostaAPI.aux == "tokenInvalido") {
					deslogar();
				}

				setCarregando(false);
				setResultado(respostaAPI);
				setErroAPI(true);
			}
		}

		if (carregando) buscarCampos()
	}, [carregando])

	useEffect(() => {
		if (!mostrarDados && !mostrarArquivos) {
			setCadastroCompleto(true)
			setTabAtiva("cadastroCompleto")
		} else {
			if (mostrarDados)
				setTabAtiva("dadosGerais")
			else if (mostrarArquivos)
				setTabAtiva("arquivos")
		}
	}, [mostrarDados, mostrarArquivos])

	useEffect(() => {
		let completo = true;

		let dadosCompletos = true;
		if (resultado?.campos) {
			Object.keys(resultado.campos).map((chave) => {
				if (resultado.campos[chave].enviado == "" && resultado.campos[chave].obrigatorio) {
					completo = false;
					dadosCompletos = false;
				} else if (resultado.campos[chave].enviado == "" && !dadosAdicionaisCompleto) {
					completo = false;
					dadosCompletos = false;
				}
			});

			if (dadosCompletos) {
				// setMostrarDados(false)
				if (!mostrarArquivos) {
					setCadastroCompleto(true);
				}
			}
		}

		if (resultado?.arquivos) {
			let arquivosCompletos = true;
			Object.keys(resultado.arquivos).map((chave) => {
				if (resultado.arquivos[chave].enviado == "" && resultado.arquivos[chave].obrigatorio) {
					completo = false;
					arquivosCompletos = false;
				} else if (resultado.arquivos[chave].enviado == "" && !dadosAdicionaisCompleto) {
					completo = false;
					arquivosCompletos = false;
				}
			});

			if (arquivosCompletos) {
				// setMostrarArquivos(false)
				if (!mostrarDados) {
					setCadastroCompleto(true);
				}
			}

			if (completo) {
				setCadastroCompleto(true);
				setDadosAdicionaisCompleto(true);
			}
		}
	}, [resultado])

	return <motion.div
		className="mx-2"
		initial={configAnim.initial}
		animate={configAnim.animate}
		exit={configAnim.exit}
		transition={configAnim.transition}
	>
		{carregando
			? <div className="vh-100">
				<Spinner />
			</div>
			: <div style={{ minHeight: "100vh" }}>
				<header>
					<button className="over" onClick={handleSair}>
						<MdLogout size={28} />
						Sair
					</button>
					<div className="floatCenter mt-1">PÓS CADASTRO</div>
				</header>
				{erroAPI
					? <>
						<Alert severity="warning" className="text-center mt-4">
							{resultado.mensagem}
						</Alert>
						<footer></footer>
					</>
					: <>
						<div>
							<h3 className="text-center mt-3">Legal! Precisamos de mais algumas informações suas.</h3>
							<div className="d-flex my-2 gap-3">
								<button disabled={tabAtiva == "dadosGerais"} onClick={() => setTabAtiva("dadosGerais")}>Dados Gerais</button>
								<button disabled={tabAtiva == "arquivos"} onClick={() => setTabAtiva("arquivos")}>Arquivos</button>
							</div>

							{tabAtiva == "dadosGerais" && (mostrarDados && resultado.campos) && <div style={{ maxHeight: "calc(100vh - 11rem)", overflowY: "auto" }}>
								{msgCampos
									? <Alert className="w-100 text-wrap">
										Todos os campos necessários já foram enviados, obrigado!
									</Alert>
									: <>
										<form id="dadosGerais">
											{Object.values(filtrarCampos(resultado.campos)).map((e, i) => {

												let atributos = { autorizado: 0 };

												if (e.obrigatorio)
													atributos["obrigatorio"] = "obrigatorio";

												if (e.enviado)
													atributos["autorizado"] = 1;


												if (e.chave == "tipoVeiculo")
													console.log(e.valorEnviado == "")

												atributos["value"] = ""
												if (e?.valorEnviado != "" && e?.valorEnviado != undefined) {

													let value = e.valorEnviado;
													if (e.chave == "datVecApolice" || e.chave == "datVecAntt") {
														value = formataDataBrasileiro(value);
													} else if (e.tipoCampo == "radio") {
														if (e.valorEnviado == "") {
															value = "";
														}
													}

													atributos["value"] = value;

													if (e?.enviado) {
														atributos["disable"] = true;
													}
												} else if (e.default)
													atributos["value"] = e.default
												else if (e?.valor != "")
													atributos["value"] = e.valor;
												else atributos["value"] = ""

												if (['dataVencimentoHabilitacao', 'numeroHabilitacao', 'possuiBau', 'quantosLitros', 'placa', 'anoFabricacao', 'renavam', 'chassi', 'nAntt', 'datVecAntt', 'cilindradas', 'condumotoAberto'].includes(e.chave))
													if (resultado.campos.tipoVeiculo.iconeEnviado == 'bikeboy.png')
														return (<></>)

												if (['possuiBau', 'quantosLitros', 'PossuiMochila', 'condumotoAberto', 'cilindradas'].includes(e.chave))
													if (['utilitario.png', 'carro.png', 'aereo.png', 'guincho.png', 'taxi.png', 'van.png', 'frete.png', 'fluvial.png'].includes(resultado.campos.tipoVeiculo.iconeEnviado))
														return (<></>)

												if (['possuiBau', 'quantosLitros'].includes(e.chave))
													if (['utilitario sem bau.png'].includes(resultado.campos.tipoVeiculo.iconeEnviado))
														return (<></>)

												if (e.chave == 'quantosLitros')
													if (e.chave == 'N')
														return (<></>)

												if (e.chave == "pix")
													return <>
														<div key={i}>
															<Zoom
																className="mt-3"
																in={true}
																style={{
																	transitionDelay:
																		i * (1000 / Object.keys(resultado.campos).length),
																}}
															>
																<div>
																	<Pix e={e} atributos={atributos} handleCampo={handleCampo} />
																</div>
															</Zoom>
														</div>
													</>
												return <div key={i}>
													<Zoom
														className="mt-3"
														in={true}
														style={{
															transitionDelay:
																i * (1000 / Object.keys(resultado.campos).length),
														}}
													>

														<div style={{
															opacity: atributos.autorizado ? 0 : 1,
															display: atributos.autorizado ? "none" : "block",
														}} >
															<Campo
																id={e.chave}
																label={e.label}
																tipo={e.tipoCampo == "date" ? "dateSelect" : e.tipoCampo}
																options={e?.opcoes}
																{...atributos}
																danger={!!e.erro ? 1 : 0}
																onChange={handleCampo}
															/>
															{!!e.erro ? <p className="text-danger">{e.erro}</p> : ""}
														</div>
													</Zoom>
												</div>
											})}
										</form>

										<div className="w-100 text-center mt-3">
											{enviando
												? <div className="pb-3">
													<Spinner />
												</div>
												: resposta}

											<button
												onClick={enviarCadastroPos}
												className="mb-2 w-100"
											>
												Enviar
											</button>
										</div>
									</>}
							</div>}

							{tabAtiva == "arquivos" && <div style={{ maxHeight: "calc(100vh - 11rem)", overflowY: "auto" }}>
								{msgArquivos
									? <Alert className="w-100 text-wrap">
										Todos os arquivos necessários já foram enviados, obrigado!
									</Alert>
									: resultado.arquivos
										? Object.values(resultado.arquivos).map((e, i) => {
											let atributos = {}

											let autorizado = false
											if (e.obrigatorio) {
												atributos["obrigatorio"] = "obrigatorio";
											}

											if (e.enviado) {
												autorizado = true;
											}

											if (e?.valorEnviado != "") {
												atributos["value"] = "Enviado com sucesso!";
												atributos["disable"] = true;
											} else {
												atributos["value"] = "";
												atributos["disable"] = false;
											}

											if (['fotoHabilitacao', 'fotoAntt', 'fotoBau', 'fotoDocVeiculo', 'condumoto'].includes(e.chave))
												if (resultado.campos.tipoVeiculo.valorEnviado == 'B')
													return (<></>)

											if (['fotoBau', 'fotoBag', 'condumoto'].includes(e.chave))
												if (resultado.campos.tipoVeiculo.valorEnviado == 'U')
													return (<></>)

											return <Arquivo
												key={i}
												e={e}
												atributos={atributos}
												autorizado={autorizado}
												handle={handleCampoArquivo}
												setCarregando={setCarregando}
											/>
										})
										: ""}
							</div>}

							{tabAtiva == "cadastroCompleto" && <div className="mt-3">
								<Gears />
								<Alert variant="filled" color="primary" className="mt-3">
									{resultado.msgAnalise}
								</Alert>

								<div className="toBottom w-100 bg-white">
									{validRedirect && <Button
										className="mb-2"
										variant="contained"
										startIcon={<MdOpenInNew />}
										onClick={() => {
											if (!!window.AndroidInterface) {
												if (typeof window.AndroidInterface.redirectLink === "function") {
													window.AndroidInterface.redirectLink(resultado.redirecionamento.url)
												}
											}
										}}
										fullWidth>
										{resultado.redirecionamento.label}
									</Button>}
								</div>
							</div>}
						</div>
					</>}
			</div>}
	</motion.div>
}

function Arquivo({ e, atributos, autorizado, handle, setCarregando }) {
	const [ver, setVer] = useState("")
	const [open, setOpen] = useState(false)

	useEffect(() => {
		if (window.AndroidInterface)
			setVer(AndroidInterface?.recoverVersion())
	}, [])

	return <div
		className="mt-3"
		style={{
			opacity: e.enviado ? 0 : 1,
			display: e.enviado ? "none" : "block",
		}}
	>
		{ver >= 102.26
			? <button variant="contained" onClick={() => setOpen(true)} className="w-100">
				{e.label}
			</button>
			: <Campo
				id={e.chave}
				label={e.label}
				tipo={e.tipoCampo}
				tabindex="-1"
				{...atributos}
				autorizado={autorizado}
				onChange={handle}
			/>}
		{open && <FotoModal
			label={e.label}
			open={open}
			close={() => setOpen(false)}
			identificador={e.chave}
			reload={() => {
				if (window.AndroidInterface)
					window.AndroidInterface.showAndroidVersion("Sucesso!")
				setOpen(false)
				setCarregando(true)
			}}
			onError={msg => {
				if (window.AndroidInterface)
					window.AndroidInterface.showAndroidVersion(msg ?? "Ops! Não foi possível salvar a imagem.")
				setOpen(false)
			}}
		/>}
	</div>
}

function FotoModal({ label, open, close, identificador, reload, onError }) {
	const { dados } = useContext(ContextoGeral)

	const [camera, setCamera] = useState(false)
	const [gallery, setGallery] = useState(false)

	return <Backdrop in={open} sx={{
		position: "absolute",
		top: 0,
		height: "100vh",
		backdropFilter: "blur(5px)",
		margin: "0 -0.5rem",
		zIndex: 5000
	}}>
		<Card className="p-3 position-relative" style={{ width: "80%" }}>
			<h5>Como deseja enviar a foto de {label}?</h5>
			<IconButton className="position-absolute end-0 top-0" onClick={close}>
				<Close />
			</IconButton>
			<div className="d-flex justify-content-between gap-3 w-100">
				<Button className="gap-1" onClick={() => {
					setCamera(true)
					setGallery(false)
				}}><CameraAlt />Câmera</Button>
				<Button className="gap-1" onClick={() => {
					setCamera(false)
					setGallery(true)
				}}><Collections />Galeria</Button>
			</div>
			{camera && <Camera
				onFinish={async URL => {
					await ProfissionalAPI.arquivosPosCadastro(dados, {
						link: URL,
						idProf: dados.idProf,
						identificador: identificador
					}).then(res => {
						if (res.resultado) {
							reload()
						} else {
							onError(res.mensagem)
						}
					})
				}}
				onError={() => {
					setCamera(false)
				}}
			/>}
			{gallery && <Gallery
				onFinish={async URL => {
					await ProfissionalAPI.arquivosPosCadastro(dados, {
						link: URL,
						idProf: dados.idProf,
						identificador: identificador
					}).then(res => {
						if (res.resultado) {
							reload()
						} else {
							onError(res.mensagem)
						}
					})
				}}
				onError={() => {
					setGallery(false)
				}}
			/>}
		</Card>
	</Backdrop>
}

function Camera({ onFinish, onError }) {
	useCamera(onFinish, onError)
	return <div className="text-center">
		<CircularProgress />
	</div>
}

function Gallery({ onFinish, onError }) {
	useGallery(onFinish, onError)
	return <div className="text-center">
		<CircularProgress />
	</div>
}

function Pix({ e, atributos, handleCampo }) {
	const [type, setType] = useState("")
	const [mask, setMask] = useState("")

	useEffect(() => {
		switch (type) {
			case "cpf":
				setMask("999.999.999-99")
				break;
			case "cnpj":
				setMask("99.999.999/0001-99")
				break;
			case "email":
				setMask("")
				break;
			case "tel":
				setMask("+55 999 99999-9999")
				break;
			case "chave":
				setMask("")
				break;
		}
	}, [type])

	return <div>
		<div className="mt-3" style={{
			opacity: atributos.autorizado ? 0 : 1,
			display: atributos.autorizado ? "none" : "block",
		}} >
			<Campo
				id="tipoPix"
				tipo="select"
				label="Tipo de chave Pix"
				options={[
					{
						key: 0,
						value: "tel",
						label: "Telefone",
					},
					{
						key: 1,
						value: "email",
						label: "E-mail",
					},
					{
						key: 2,
						value: "cpf",
						label: "CPF",
					},
					{
						key: 3,
						value: "cnpj",
						label: "CNPJ",
					},
					{
						key: 4,
						value: "chave",
						label: "Chave Aleatória",
					},
				]}
				value={type}
				onChange={e => setType(e.target.value)}
			/>

			<div className="mt-3">
				<Campo
					id={e.chave}
					label={e.label}
					tipo={e.tipoCampo == "date" ? "dateSelect" : e.tipoCampo}
					mask={mask}
					options={e?.opcoes}
					{...atributos}
					disabled={type == ""}
					danger={!!e.erro ? 1 : 0}
					onChange={handleCampo}
				/>
				{!!e.erro ? <p className="text-danger">{e.erro}</p> : ""}
			</div>
		</div>
	</div>
}