import { reduceImage, resizePic } from "../../servicos/Uteis"

export default function Camera({ func, setState }) {
	const endFunction = async (e) => {
		setState(true)

		try {
			const file = e.target.files[0]

			if (file) {
				const FR = new FileReader()

				if (file.name.match(/\.(jpg|jpeg|png)$/i)) {
					const image = await resizePic(file)

					FR.onloadend = async () => {

						if (FR.error) {
							// Lógica de tratamento de erro, se necessário.
							console.error('Ocorreu um erro ao processar o arquivo:', FR.error);
							setState(false)

						} else {
							if (FR.result) {
								await func(FR.result);
								// Lógica de sucesso, se a leitura foi bem-sucedida e FR.result não é nulo.
							} else {
								setState(false)
							}
						}
					}

					FR.onerror = (event) => {

						let mensagem = 'Ocorreu um erro no FR.onerro';
						if(event.error?.message){
							mensagem = `Ocorreu um erro durante a operação: ${event.error.message}`;
						}else if( event?.target.error.message) {
							mensagem = `Ocorreu um erro durante a operação: ${event.target.error.message}`;
						}
						// Lógica de tratamento de erro, se necessário.
					};

					FR.readAsDataURL(image)
				} else  throw Error("Favor selecione uma imagem válida")
			} else throw Error("Nenhuma imagem selecionada")
		} catch (err) {
			alert(err)
			setState(false)
		}
	}

	return <div>
		<input
			id="captura"
			type="file"
			onChange={endFunction}
			style={{
				fontSize: "0.5px",
				height: "100%",
				width: "100%",
				opacity: 0
			}}
		/>
	</div>
}