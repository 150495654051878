import styled from "styled-components"

export const Div = styled.div`
    position: absolute;
    display: ${props => (props.ajustado ? 'flex' : 'block')};    
    align-items: ${props => (props.ajustado ? 'center' : 'none')};
    justify-content: ${props => (props.ajustado ? 'space-between' : 'none')};
    width: ${props => (props.ajustado ? '100vw' : 'auto')};
    transform: ${props => (props.centralizado ? 'translate(50%)' : '')};    
    box-sizing: border-box;

    top     : ${props => props.top};
    bottom  : ${props => props.bottom};
    left    : ${props => props.left};
    right   : ${props => props.right};
    z-index: 505;
`
export function BlocoFlutuante(props) {

    let attr = {}
    if (props.ajustado) {
        attr = { ajustado: true }
    }

    if (props.centralizado) {
        attr = { centralizado: true }
    }

    return (
        <Div
            top={props.top}
            bottom={props.bottom}
            left={props.left}
            right={props.right}
            {...attr}
        >
            {props.children}
        </Div>
    )
}