import { useContext, useEffect, useState } from "react"
import { Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { ContextoGeral } from "../../contextos/ContextoGeral"
import { differenceInSeconds } from "date-fns"
import { verificarFakeGPS } from "../../servicos/Uteis"
import MensagemApp from "../../servicos/config/MensagemApp"
import ProfissionalAPI from "../../servicos/ProfissionalAPI"

export default function FakeGPSBlock() {
	const { dados } = useContext(ContextoGeral)

	const [open, setOpen] = useState(false)
	const [active, setActive] = useState(true)

	const [time, setTime] = useState(60)

	useEffect(() => {
		if (open) {

			if (typeof window.clearFakeGPSBlock == "undefined") {
				window.clearFakeGPSBlock = function () {
					setTime(60)
				}
			}
		}

		try {
			if (!verificarFakeGPS() && window.AndroidInterface?.isGPSFake && window.AndroidInterface.isGPSFake() == "S" && dados.configFakeGPS.opcao == "B")
				setOpen(true)
		} catch {
			console.error(MensagemApp.funcIndisponivel)
		}
	}, [open, active])

	useEffect(() => {
		if (open) {
			const interval = setInterval(() => {
				setTime(prev => prev - 1)
			}, 1000)

			return () => {
				delete window.clearFakeGPSBlock
				clearInterval(interval)
			}
		}
	}, [open])

	useEffect(() => {
		if (time == 0) {
			try {
				window.AndroidInterface?.removeGPSFake()
				setActive(false)
				setOpen(false)
			} catch {
				console.error(MensagemApp.funcIndisponivel)
			}

			setActive(false)
		}
	}, [time])

	return <Dialog open={open}>
		<DialogTitle className="text-center">Atenção</DialogTitle>
		<DialogContentText className="px-3">
			Você estava utilizando a permissão de um aplicativo de localização fictícia, é necessário aguardar o tempo abaixo para poder acessar a funcionalidade desta tela novamente.
		</DialogContentText>
		<DialogContent style={{ width: "80vw" }} className="text-center">
			<span style={{
				fontSize: "5rem",
			}}>
				{time}
			</span>
		</DialogContent>
	</Dialog>
}