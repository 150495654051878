export const configAnim = {
    initial: {
        x: '100%',
        zIndex: 500
    },
    animate: {
        x: '0%',
    },
    exit: {
        x: '-100%',
    },
    transition: {
        ease: 'easeInOut'
    }
}

export const campos = {
    initial: {
        height: 0
    },
    animate: {
        height: 'auto'
    },
    exit: {
        height: 0
    },
    transition: {
        ease: 'easeInOut'
    }
}

export function calcAnimTime(i, len) {
    if (len > 2) {
        return i * (1000 / len)
    } else {
        return i * 200
    }
}