export default function validarRedirecionamento(chave) {
    switch(chave) {
        case 'servico-execucao':
            return '/app/servicos'
        case 'servico-cotacao':
            return '/app/cotacoes'
        case 'servico-mensagens':
            return '/app/mensagens'
        default:
            return '/home'
    }
}